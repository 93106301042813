<template>
    <div class="">
        <header class="border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]">
            <div @click="router.back" class="cursor-pointer">
                <div class="rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative">
                    <Icon icon="ant-design:arrow-left-outlined" width="18" height="18" class="text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </div>
            </div>
            <h3 class="font-medium text-sub-heading">Back to checkout</h3>
        </header>

        <main class="lg:w-[35%] w-[90%] mx-auto mt-[40px]">
            <div class="border-[1px] border-grey-50 rounded-lg ">
                <div class="bg-[#D9D9D966] py-[12px] text-center rounded-t-lg">THIS ACCOUNT WILL EXPIRE IN</div>
                <div class="flex items-center gap-[12px] py-[12px] w-fit mx-auto">
                    <div class="p-[10px]">
                        <div class="lg:text-heading-3 text-header-4">{{ countdown.days }}</div>
                        <div class="lg:text-body-2 text-caption text-grey-200">DAYS</div>
                    </div>
                    <div class="">:</div>
                    <div class="p-[10px]">
                        <div class="lg:text-heading-3 text-header-4">{{ countdown.hours }}</div>
                        <div class="lg:text-body-2 text-caption text-grey-200">HOURS</div>
                    </div>
                    <div class="">:</div>
                    <div class="p-[10px]">
                        <div class="lg:text-heading-3 text-header-4">{{ countdown.minutes }}</div>
                        <div class="lg:text-body-2 text-caption text-grey-200">MIN</div>
                    </div>
                    <div class="">:</div>
                    <div class="p-[10px]">
                        <div class="lg:text-heading-3 text-header-4">{{ countdown.seconds }}</div>
                        <div class="lg:text-body-2 text-caption text-grey-200">SEC</div>
                    </div>
                </div>
            </div>

            <div class="mt-[24px]">
                <div class="bg-link py-[16px] text-white text-center rounded-t-lg">To complete your order, please make bank transfer to the account below</div>
                <div class="text-grey-200">
                    <div v-if="loading" class="flex justify-center items-center py-[16px]">
                        <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-grey-100"></div>
                    </div>
                    <div v-else>
                        <div class="flex text-body-2 border-[0.5px] border-grey-50">
                            <div class="w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]">Transfer reference</div>
                            <div class="w-[70%] py-[16px] px-[24px]">{{ bankData?.meta?.authorization?.transfer_reference }}</div>
                        </div>
                        <div class="flex text-body-2 border-[0.5px] border-grey-50">
                            <div class="w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]">Account Number</div>
                            <div class="w-[70%] py-[16px] px-[24px]">{{ bankData?.meta?.authorization?.transfer_account }}</div>
                        </div>
                        <div class="flex text-body-2 border-[0.5px] border-grey-50">
                            <div class="w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]">Bank Name</div>
                            <div class="w-[70%] py-[16px] px-[24px]">{{ bankData?.meta?.authorization?.transfer_bank }}</div>
                        </div>
                        <div class="flex text-body-2 border-[0.5px] border-grey-50">
                            <div class="w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]">Transfer Note</div>
                            <div class="w-[70%] py-[16px] px-[24px]">{{ bankData?.meta?.authorization?.transfer_note }}</div>
                        </div>
                        <div class="flex text-body-2 border-[0.5px] border-grey-50">
                            <div class="w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]">Transfer Amount</div>
                            <div class="w-[70%] py-[16px] px-[24px]">₦{{ parseFloat(bankData?.meta?.authorization?.transfer_amount).toLocaleString() }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-grey-300 my-[20px] font-bold">After you've made Payment, please click on the verify button for your payment to be confirmed.</div>
            <div class="text-error font-bold">Please do not refresh this page.</div>

            <div class="my-[20px] flex items-center justify-end gap-[10px]">
                <ActionButton label="Cancel" theme="outlined" class="w-fit" :handleClick="router.back" />
                <ActionButton label="Verify" class="bg-error text-white w-fit" :handleClick="() => verifyPayment()" />
            </div>
        </main>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import router from '@/router';
import PaymentService from '@/utility/services/microsite/payment.service';
import StoreService from '@/utility/services/microsite/store.service';
import { Icon } from '@iconify/vue';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const bankData = ref();
const countdown = ref({ days: 0, hours: 0, minutes: 0, seconds: 0 });
const loading = ref(true);

const user = ref<any>(JSON.parse(localStorage.getItem('user') || 'null')); 

watch(user, (newValue) => {
    if (newValue) {
        localStorage.setItem('user', JSON.stringify(newValue)); 
    }
});

watch(
    () => localStorage.getItem('user'),
    (newValue) => {
        user.value = JSON.parse(newValue || 'null'); 
    }
);

const route = useRoute();

onMounted(async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params;

    const cartData = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
    const totalPrice = parseFloat(cartData.totalPrice.replace(/₦|,/g, ''));

    console.log({cartData})

    const data = {
        orderId,
        orderChannel: "Microsite",
        payment: {
            firstName: user.value.firstName,
            lastName: user.value.lastName,
            email: user.value.email,
            phone: user.value.phone,
            amount: totalPrice,
            paymentOption: "BankTransfer",
        }
    }
    try {
        bankData.value = await PaymentService.initiate(`${storeId}`, data);
        startCountdown(bankData.value.meta.authorization.account_expiration);
    } catch (error) {
        console.error("Error fetching bank data:", error);
    } finally {
        loading.value = false;
    }
});

function startCountdown(expirationTime: any) {
    const expirationDate = new Date(expirationTime).getTime();
    const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = expirationDate - now;

        if (distance < 0) {
            clearInterval(interval);
            countdown.value = { days: 0, hours: 0, minutes: 0, seconds: 0 };
            return;
        }

        countdown.value.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        countdown.value.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        countdown.value.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        countdown.value.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }, 1000);
}

const verifyPayment = () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params; 

    router.push({
        path: `/verify_payment/${bankData?.value?.meta?.authorization?.transfer_reference}/${orderId}`,
        query: { storeId, restaurantId } 
    })
}

console.log(bankData.value);

</script>

<style scoped>
/* No additional styles needed as Tailwind CSS is used for the loader */
</style>