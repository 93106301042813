import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]" }
const _hoisted_3 = { class: "rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative" }
const _hoisted_4 = { class: "grid lg:grid-cols-2" }
const _hoisted_5 = { class: "lg:h-[90vh] bg-grey-50 overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px] no-scrollbar" }
const _hoisted_6 = { class: "font-medium text-caption text-grey-200" }
const _hoisted_7 = { class: "flex gap-[8px]" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "text-caption text-grey-200" }
const _hoisted_11 = { class: "text-caption text-right grid gap-[8px]" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "text-grey-200" }
const _hoisted_14 = { class: "py-[24px] grid gap-[16px]" }
const _hoisted_15 = { class: "flex items-center justify-between" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "flex items-center justify-between" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "flex items-center justify-between" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = { class: "h-[90vh] overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px]" }
const _hoisted_23 = { class: "grid gap-[8px] my-[42px]" }
const _hoisted_24 = { class: "" }
const _hoisted_25 = { class: "text-body-2 font-medium" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "text-body-2 font-medium" }
const _hoisted_28 = { class: "" }
const _hoisted_29 = { class: "text-body-2 font-medium" }
const _hoisted_30 = { class: "mb-[42px]" }
const _hoisted_31 = { class: "grid gap-[16px] text-grey-200 text-body-2 mt-[16px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import StoreService from '@/utility/services/microsite/store.service';
import { Icon } from '@iconify/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { formatPrice } from '@/utility/helper/utils'
import router from '@/router';

interface CartItem extends Record<string, string | number> {
    imageUrl: string;
    name: string;
    price: string;
    quantity: number;
    totalPrice: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutView',
  setup(__props) {

const user = ref<any>(JSON.parse(localStorage.getItem('user') || 'null')); 
const isChecked = ref<boolean>(false)

watch(user, (newValue) => {
    if (newValue) {
        localStorage.setItem('user', JSON.stringify(newValue)); 
    }
});

watch(
    () => localStorage.getItem('user'),
    (newValue) => {
        user.value = JSON.parse(newValue || 'null'); 
    }
);

const getCartBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const makePayment = async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params
    router.push({ 
        path: `/initiate/${orderId}`, 
        query: { storeId, restaurantId } 
    })
}

const cartMenus = ref<CartItem[]>([]);
const orderData = ref()

const route = useRoute();

onMounted(async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params; 
    try {
        const cartData = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
        orderData.value = cartData
        cartMenus.value = cartData.orderDetails || [];
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
});

const totalPrice = computed(() => {
    return cartMenus.value.reduce((acc, item) => acc + item.totalPrice, 0);
});

const handleIsChecked = () => {
    isChecked.value = !isChecked.value
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(router).back && _unref(router).back(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:arrow-left-outlined",
            width: "18",
            height: "18",
            class: "text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-medium text-sub-heading" }, "Back to cart", -1))
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "font-medium" }, "Order Information & Review", -1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[4] || (_cache[4] = _createTextVNode("By placing your order, you agree to foodcart's ")),
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: "text-primary-300 underline"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Privacy")
            ])),
            _: 1
          }),
          _cache[5] || (_cache[5] = _createTextVNode(" and ")),
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: "text-primary-300 underline"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Policy")
            ])),
            _: 1
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartMenus.value, (menu, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex justify-between cursor-pointer border-b-[1px] border-solid border-grey-50 py-[24px]"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                style: _normalizeStyle(getCartBackgroundStyle(menu.imageUrl, 60))
              }, null, 4),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(menu.name), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(menu.description), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(menu.price), 1),
              _createElementVNode("div", _hoisted_13, "Qty: " + _toDisplayString(menu.quantity), 1)
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Sub Total", -1)),
            _createElementVNode("div", _hoisted_16, _toDisplayString(orderData.value?.subTotalPrice), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Service Fee", -1)),
            _createElementVNode("div", _hoisted_18, _toDisplayString(orderData.value?.serviceFee || '2'), 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "" }, "Total", -1)),
            _createElementVNode("div", _hoisted_20, _toDisplayString(orderData.value?.totalPrice), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "font-medium" }, "Payment Details", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "font-medium text-caption text-grey-200" }, "Complete your purchase by providing your information and make payment", -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Name", -1)),
              _createElementVNode("div", _hoisted_25, _toDisplayString(user.value?.firstName + ' ' + user.value?.lastName), 1)
            ]),
            _createElementVNode("div", _hoisted_26, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Email", -1)),
              _createElementVNode("div", _hoisted_27, _toDisplayString(user.value?.email), 1)
            ]),
            _createElementVNode("div", _hoisted_28, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Phone", -1)),
              _createElementVNode("div", _hoisted_29, _toDisplayString(user.value?.phone), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "font-medium" }, "Payment Details", -1)),
            _createElementVNode("div", _hoisted_31, [
              _createVNode(RadioButton, {
                value: "Pay with Bank Transfer (Recommended)",
                isChecked: isChecked.value,
                onChange: handleIsChecked
              }, null, 8, ["isChecked"])
            ])
          ]),
          _createVNode(ActionButton, {
            theme: "primary",
            label: "Make Payment",
            class: "whitespace-nowrap",
            handleClick: () => makePayment(),
            disable: !isChecked.value
          }, null, 8, ["handleClick", "disable"])
        ])
      ])
    ])
  ]))
}
}

})