import { useForm } from "@/utility/composables/useForm";
import AuthService from "@/utility/services/auth.service";
import cartService from "@/utility/services/microsite/cart.service";
import StoreService from "@/utility/services/microsite/store.service";
import { Menu, Store } from "@/utility/types/store";
import { forEach } from "lodash";
import { computed, reactive, ref, watch } from "vue";
import { useRoute } from 'vue-router';


export const useSignup = (storeX?: any, callback?: any) => {
    let signupResponseData = reactive({})
    const signupFormHandler = useForm({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: ''
      },
      onSubmit: async (values: any) => {
        try {
          await AuthService.signupRequest(values).then((res: any) => {
            signupResponseData = res
            console.log({signupResponseData: signupResponseData}, 'axios');
            callback?.();
            storeX.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Success', x_message: `Signup Successful`, duration: 5000 });
          })
        } catch (error: any) {
          console.error('signup failed', error);
          storeX.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'An error occured!', x_message: `${error.response.data.error}`, duration: 5000 });
        }
      }
    });
  
    const allFieldsFilled = computed(() => {
      return Object.keys(signupFormHandler.values).every((key) => signupFormHandler.values[key] && !signupFormHandler.errors[key]);
    });
  
    return {
      signupFormHandler,
      signupResponseData,
      allFieldsFilled
    };
};

export const useLogin = (storeX?: any, data?: any) => {
    const loginFormHandler = useForm({
      initialValues: {
        email: '',
        password: ''
      },
      onSubmit: async (values: any) => {
        console.log(values)
        try {
          await AuthService.loginRequest(values).then(async (response: any) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            if (localStorage.getItem('cartItems')) {
              const storageCartItems: any = ref();
              storageCartItems.value = JSON.parse(`${localStorage.getItem('cartItems')}`);
              await forEach(storageCartItems.value, async (cart) => {
                await cartService.addToCart(response.data.user?.id, [cart]);
              });

              data.callback.checkout(response.data.user)
            } else {
              return window.location.reload()
            }
            localStorage.removeItem("cartItems")
          })
        } catch (error: any) {
          console.error('login failed', error);
          storeX.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'An error occured!', x_message: `${error.response.data.error}`, duration: 5000 });

        }
      }
    });
  
    const allFieldsFilled = computed(() => {
      return Object.keys(loginFormHandler.values).every((key) => loginFormHandler.values[key] && !loginFormHandler.errors[key]);
    });
  
    return {
      loginFormHandler,
      allFieldsFilled
    };
};

export const getRestaurants = () => {
    const stores = ref<Store[]>([]);
    const loading = ref(false);
    const error = ref<string | null>(null);

    const getStores = async () => {
        loading.value = true;
        error.value = null;
        try {
            const response = await StoreService.storesRequest();
            stores.value = response;
        } catch (err) {
            console.error(err);
            error.value = "Failed to fetch stores";
        } finally {
            loading.value = false;
        }
    };

    getStores();

    return {
        stores,
        loading,
        error,
    };
};

export const getRestaurantMenu = () => {
    const route = useRoute();
    const restaurantId = route.params.restaurantId; 
    const storeId = route.params.storeId; 
    const storeMenu = ref<Menu[]>([]);
    const store = ref<any>();
    const categories = ref<any[]>([]);
    const loading = ref(false);
    const error = ref<string | null>(null);
    const selectedCategory = ref(route.query.category ?? "")
    
    const getStoreMenu = async () => {
        loading.value = true;
        error.value = null;
        try {
            const storeResponse = await StoreService.storeRequest(`${restaurantId}`, `${storeId}`);
            const response = await StoreService.storeMenuRequest(`${restaurantId}`, `${storeId}`, `${selectedCategory.value}`);
            const storeCategoriesResponse = await StoreService.storeCategoriesRequest(`${restaurantId}`, `${storeId}`);
            storeMenu.value = response.menus;
            store.value = storeResponse
            categories.value = storeCategoriesResponse.categories
        } catch (err) {
            console.error(err);
            error.value = "Failed to fetch stores";
        } finally {
            loading.value = false;
        }
    };

    getStoreMenu();

    watch(() => route.query.category, (newCategory) => {
        selectedCategory.value = newCategory ?? "";
        getStoreMenu();
    });
    
    return {
        storeMenu,
        store,
        selectedCategory,
        categories,
        loading,
        error,
    };
};

export const getAuthInformation = () => {
  const userData = ref();
  const token = ref();
  userData.value = localStorage.getItem('user')
  token.value = localStorage.getItem('token')

  return { userData: JSON.parse(userData.value), token: token.value }
}


export const useLogout = async(callback: () => void) => {
    return await AuthService.logoutRequest().then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        callback?.();
    })
}


export const items = ref([
    { 
        icon: require('@/assets/images/icons/table.png'), 
        label: 'All' 
    },

    { 
        icon: require('@/assets/images/icons/chicken.png'), 
        label: 'Chicken' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cooked.png'), 
        label: 'Rice' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cupcake.png'), 
        label: 'Cup Cake' 
    }, 

    { 
        icon: require('@/assets/images/icons/HotSoup.png'), 
        label: 'Soup' 
    }, 

    { 
        icon: require('@/assets/images/icons/JuiceGlass.png'), 
        label: 'Parfait' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cabbage.png'), 
        label: 'Salad' 
    }, 

    { 
        icon: require('@/assets/images/icons/Spaghetti.png'), 
        label: 'Pasta' 
    }, 

    { 
        icon: require('@/assets/images/icons/Porridge.png'), 
        label: 'Porridge' 
    }, 

    { 
        icon: require('@/assets/images/icons/lemonjuice.png'), 
        label: 'Juice' 
    }, 

    { 
        icon: require('@/assets/images/icons/Smoothie.png'), 
        label: 'Smoothie' 
    }, 

    { 
        icon: require('@/assets/images/icons/Hamburger.png'), 
        label: 'Hamburger'
    }
]);