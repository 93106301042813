import ApiService from "../api.service"
import { endpoint } from "@/utility/endpoint"
import { Menu, Store } from "@/utility/types/store"

export default class StoreService {
    
    static async storesRequest(): Promise<Store | any> {
        const response = await ApiService.get(endpoint.stores)
        return response.data.data
    }

    static async storeMenuRequest(restaurantId: string, storeId: string, query: string): Promise<Menu | any> {
        const url = endpoint.store_menus.replace(':restaurantId', restaurantId).replace(':storeId', storeId)
        const response = await ApiService.get(`${url}${query == undefined ? "" : `?category=${query}`}`)
        return response.data.data
    }

    static async storeCategoriesRequest(restaurantId: string, storeId: string): Promise<Menu | any> {
        const url = endpoint.store_categories.replace(':restaurantId', restaurantId).replace(':storeId', storeId)
        const response = await ApiService.get(url)
        return response.data.data
    }

    static async storeRequest(restaurantId: string, storeId: string): Promise<any> {
        const url = endpoint.store.replace(':restaurantId', restaurantId).replace(':storeId', storeId)
        const response = await ApiService.get(url)
        return response.data.data
    }

    static async storeOrderByIdRequest(restaurantId: any, storeId: any, orderId: any): Promise<any> {
        const url = endpoint.orderById.replace(':restaurantId', restaurantId).replace(':storeId', storeId).replace(':orderId', orderId)
        const response = await ApiService.get(url)
        return response.data.data
    }
}