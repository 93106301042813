import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px]" }
const _hoisted_2 = { class: "shadow-md rounded-[200px] bg-white relative" }
const _hoisted_3 = { class: "w-fit mx-auto pt-[32px] relative" }
const _hoisted_4 = { class: "lg:px-[60px] mt-[60px] mb-[-30px]" }
const _hoisted_5 = { class: "grid lg:grid-cols-4 lg:gap-[32px] gap-[20px] px-[20px]" }
const _hoisted_6 = { class: "p-[32px] text-center" }
const _hoisted_7 = { class: "font-semibold mt-[12px] text-heading-4 truncate" }
const _hoisted_8 = { class: "my-[20px] text-grey-200 h-[120px] overflow-hidden" }
const _hoisted_9 = { class: "flex items-center gap-[10px] mt-[28px]" }
const _hoisted_10 = { class: "px-[28px] pt-[32px]" }
const _hoisted_11 = { class: "text-body-1 text-grey-200 whitespace-pre-wrap" }
const _hoisted_12 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_13 = { class: "mt-[48px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
// import { Icon } from '@iconify/vue';
import { blogs } from '@/views/blog/blog.helper'
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogView',
  setup(__props) {

const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fmedium-shot-woman-reading-coffee-shop_23-2150183723.jpg&f=1&nofb=1&ipt=da1acdcebe4df8352a14d44946aaf761e9eb44b275eb536b95efd8cbe24be3cd&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const isModalOpen = ref(false);
const selectedRestaurant = ref();

const openModal = (restaurant: any) => {
    selectedRestaurant.value = restaurant; 
    isModalOpen.value = true; 
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedRestaurant.value = null; 
};

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', // Adjust the height as per your design
    width: '100%',
    borderRadius: '8px 8px 0 0'
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AppNavbar)
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[260px] mt-[150px] text-white font-bold relative" }, [
        _createElementVNode("div", { class: "px-[60px] lg:flex justify-center gap-[20px]" }, [
          _createElementVNode("div", { class: "" }, "Blog &"),
          _createElementVNode("div", { class: "bg-white text-primary-300 lg:px-[20px] rounded-[16px]" }, "Stories")
        ])
      ], -1)),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/16.png'),
        width: "140",
        height: "140",
        class: "absolute top-[200px] right-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/17.png'),
        width: "140",
        height: "140",
        class: "absolute bottom-[50px] left-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 lg:mt-[40px] mt-[20px] text-[#FFFFFFBF] relative" }, " The good stuff. Follow our social media for the latest updates, features and even sneak peeks 👀 ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/registration" }, {
          default: _withCtx(() => [
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Register Now",
              class: "rounded-[99.9px]"
            })
          ]),
          _: 1
        })
      ])
    ], 4),
    _createElementVNode("main", _hoisted_4, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"whitespace-nowrap overflow-auto px-[20px] no-scrollbar\"><div class=\"flex lg:gap-[24px] gap-[10px] mb-[32px] border-b-[1px] w-fit font-medium text-grey-100\"><div class=\"p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer\">All</div><div class=\"p-[16px] cursor-not-allowed\">News</div><div class=\"p-[16px] cursor-not-allowed\">Marketings</div><div class=\"p-[16px] cursor-not-allowed\">Vendors</div><div class=\"p-[16px] cursor-not-allowed\">Operations</div></div></div>", 1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blogs), (blog, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "border-[1px] border-grey-50 rounded-[8px]"
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(getBackgroundStyle(blog.imageUrl))
            }, null, 4),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(blog.name), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(blog.location), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(ActionButton, {
                  theme: "secondary",
                  label: "Read More",
                  onClick: ($event: any) => (openModal(blog))
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        }), 128))
      ]),
      _createVNode(AppModal, {
        headerContent: selectedRestaurant.value?.name,
        openModal: isModalOpen.value,
        onClose: closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(selectedRestaurant.value?.content), 1)
          ]),
          _createElementVNode("footer", _hoisted_12, [
            _createVNode(ActionButton, {
              label: "Close",
              theme: "secondary",
              onClick: closeModal,
              class: "w-fit"
            })
          ])
        ]),
        _: 1
      }, 8, ["headerContent", "openModal"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(AppFooter)
    ])
  ]))
}
}

})