<template>
    <AppWrapper>
        <section class="lg:px-[60px] lg:py-[100px] p-[20px]">
            <header class="">
                <div class="lg:text-[44px] text-[32px] font-semibold font-sourceSerifPro">How it works</div>
                <div class="font-sub-heading text-grey-200">Discover the simplicity behind it all and see for yourself.</div>
            </header>

            <main class="mt-[62px]">
                <div class="relative" style="
                    width: 100%;
                    height: 536px;
                    border-radius: 0px 60px 0px 60px;
                    background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fside-view-woman-working-service-industry_23-2150722790.jpg&f=1&nofb=1&ipt=a553054b77f81cc1da3aac94ddfca8a46983a1f94a50be519919c59e563674b4&ipo=images');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                ">
                    <div 
                        class="absolute inset-0 h-[70%] top-[auto]" 
                        style="
                            background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                            border-radius: 0px 0 0px 60px;
                        "
                    ></div>

                    <div class="text-white lg:w-[550px] absolute bottom-[40px] lg:left-[40px] px-[20px]">
                        <!-- <div class="text-heading-6 font-bold">
                            1 - 3
                        </div> -->

                        <div class="text-heading-4 my-[12px] font-bold">
                            Effortlessly manage your online orders with our custom microsite
                        </div>

                        <div class="text-[#FFFFFFCC] font-medium">
                            Our restaurant management platform simplifies your daily tasks, allowing you to focus on what matters most - serving your customers.
                        </div>
                    </div>
                </div>
            </main>
        </section>

        <section>
            <header class="text-center lg:text-[44px] text-[26px] font-bold font-sourceSerifPro lg:mt-0 mt-[20px]">
                <div>
                    Streamline your restaurant operations 
                    <br /> with our intuitive 
                    <span class="text-primary-300">microsite</span> and 
                    <br /> 
                    <span class="text-primary-300">register app.</span>
                </div>
            </header>

            <!-- <div class="text-heading-5 flex items-center gap-[10px] text-link w-fit px-[28px] py-[14px] mx-[auto] my-[24px] cursor-pointer">
                <Icon icon="ant-design:play-circle-filled" width="38" height="38"  />
                <span>Watch the video</span>
            </div> -->
        </section>

        <!-- <section class="pl-[60px] py-[100px]">
            <header class="flex items-center justify-between pr-[60px]">
                <div>
                    <div class="text-[44px] font-semibold font-sourceSerifPro">Happy Customers</div>
                    <div class="font-sub-heading text-grey-200">Hear what restaurant owners have to say about our platform</div>
                </div>

                <div class="flex items-center gap-[10px]">
                    <div class="w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative">
                        <Icon icon="material-symbols:arrow-left-alt" width="24" height="24" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
    
                    <div class="w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative">
                        <Icon icon="material-symbols:arrow-right-alt" width="24" height="24" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
                </div>
            </header>

            <main class="my-[62px] overflow-auto no-scrollbar">
                <div class="flex items-center gap-[20px]">
                    <div v-for="(testimonial, index) in testimonials" :key="index" class="relative" :style="getBackgroundStyle(testimonial.imageUrl)">
                        <div 
                            class="absolute inset-0 h-[70%] top-[auto]" 
                            style="
                                background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                                border-radius: 0px 0px 20px 20px ;
                            "
                        ></div>

                        <div class="text-white absolute bottom-[20px] left-[20px] right-[20px]">
                            <div class="text-heading-6 font-bold">
                                <StarRating v-model="testimonial.rating" :maxStars="5" />
                            </div>

                            <div class="mt-[16px] mb-[20px] font-medium">
                                {{ testimonial.text }}
                            </div>

                            <div class="text-[#FFFFFFCC] text-caption">
                                <div>{{ testimonial.author }}</div>
                                <small>{{ testimonial.position }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </section> -->

        <section class="lg:px-[60px] px-[20px] mt-[40px]" id="contact">
            <header class="">
                <div class="lg:text-[44px] text-[32px] font-semibold font-sourceSerifPro">Contact Us</div>
                <div class="font-sub-heading text-grey-200">Have a question? Get in touch with us!</div>
            </header>

            <main class="">
                <form @submit.prevent="handleRegistrationSubmit($event)">
                    <div class="grid lg:grid-cols-2 items-center lg:justify-between lg:gap-[82px]">
                        <div class="grid gap-[16px] lg:mt-0 mt-[20px]">
                            <InputField 
                                placeholder="Your Name" 
                                type="text" 
                                name="name" 
                                prefixIcon="ant-design:user-outlined" 
                                @update:blur:value="contactUsFormHandler.handleBlur"
                                @update:value="contactUsFormHandler.handleChange"
                            />

                            <InputField 
                                placeholder="Your Email Address" 
                                type="email" 
                                name="email" 
                                prefixIcon="ant-design:mail-outlined" 
                                @update:blur:value="contactUsFormHandler.handleBlur"
                                @update:value="contactUsFormHandler.handleChange"
                            />

                            <InputField 
                                placeholder="Your Phone number" 
                                type="tel" 
                                name="phone" 
                                prefixIcon="ant-design:phone-outlined"
                                @update:blur:value="contactUsFormHandler.handleBlur"
                                @update:value="contactUsFormHandler.handleChange"
                            />

                            <!-- <DropdownField defaultOptionLabel="Select a topic" optionsData="" class="bg-black text-white rounded-[8px]" /> -->

                            <TextArea 
                                placeholder="Type a meessage..."  
                                name="notes" 
                                @update:blur:value="contactUsFormHandler.handleBlur"
                                @update:value="contactUsFormHandler.handleChange"
                            />
                        </div>
                        <div class="lg:block hidden" style="
                            width: 100%;
                            height: 574px;
                            border-radius: 0px 60px 0px 60px;
                            background-image: url('https://img.freepik.com/premium-photo/office-black-people-workers-sitting-desks-black-man_1124848-142113.jpg?w=1380');
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                        "></div>
                    </div>

                    <ActionButton label="Submit Message" :isSubmit="true" theme="primary" class="w-fit lg:mt-0 mt-[20px]"/>
                </form>
            </main>
        </section>

        <AppFaq id="faq" />

        <section class="lg:px-[60px] lg:py-[100px] py-[30px]">
            <main class="lg:mt-[62px] mt-[32px]">
                <div class="relative lg:rounded-[60px]" style="
                    width: 100%;
                    height: 404px;
                    background-image:url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Ffront-view-woman-working-service-industry_23-2150722810.jpg&f=1&nofb=1&ipt=8c34bc878e89159fe8622dc2fb0b3c444171ef09613564faf015a6af8e1290e9&ipo=images');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                ">
                    <div 
                        class="absolute inset-0 lg:rounded-[60px] rounded-0" 
                        style="
                            background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                        "
                    ></div>

                    <div class="text-white absolute top-[72px] lg:left-[80px] left-[20px]">
                        <div class="lg:text-heading-2 text-heading-5 my-[12px] font-bold">
                            Streamline your restaurant <br /> operations
                        </div>

                        <div class="text-[#FFFFFFCC] text-sub-heading font-medium mt-[16px] mb-[24px]">
                            Discover our restaurant management platform and boost your business <br /> efficiency.
                        </div>

                        <router-link to="/registration"><ActionButton label="Get Started" theme="outlined" class="w-fit text-white border-white" /></router-link>
                    </div>
                </div>
            </main>
        </section>
    </AppWrapper>
</template>

<script setup lang="ts">
import { ref } from 'vue';
// import StarRating from '../../components/shared/ratings/StarRating.vue'
// import { Icon } from '@iconify/vue';
import AppWrapper from '../../components/app/app-wrapper/AppWrapper.vue'
import InputField from '@/components/shared/input-field/InputField.vue';
// import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
import { useContactUs } from '@/utility/composables/useAuthSession';
import { useRouter } from 'vue-router';


const router = useRouter();
const contactUsData = ref();
const { contactUsFormHandler } = useContactUs();

const handleRegistrationSubmit = async (event: Event) => {
    event.preventDefault();
    await contactUsFormHandler.handleSubmit(event);
    contactUsData.value = contactUsFormHandler.values;

    router.push('/contactUs');
};



// const testimonials = ref([
//     {
//         imageUrl: 'https://as1.ftcdn.net/v2/jpg/04/95/85/62/1000_F_495856243_oEH1nXFgcA7yBe1E6V0jAOv2efs1wMzj.jpg',
//         rating: 5,
//         text: "Our restaurant management platform has revolutionized the way we run our business. It's efficient, user-friendly, and has increased our revenue significantly.",
//         author: 'John Doe',
//         position: 'Owner, ABC Restaurant'
//     },
// ]);

// function getBackgroundStyle(imageUrl: string) {
//     return {
//         minWidth: '392px',
//         height: '608px',
//         borderRadius: '20px',
//         backgroundImage: `url(${imageUrl})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//     };
// }

</script>
