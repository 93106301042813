import ApiService from "../api.service";
import { endpoint } from "@/utility/endpoint";

export default class PaymentService {

    static async initiate(storeId: string, data: any): Promise<any> {
        const url = endpoint.initiate_payment.replace(':storeId', storeId);
        const response = await ApiService.post(url, data);
        return response.data.data;
    }

    static async verify(transactionId: string, storeId: string, orderId: string): Promise<any> {
        const url = endpoint.verify_payment.replace(':storeId', storeId).replace(':transactionId', transactionId).replace(':orderId', orderId);
        const response = await ApiService.get(url);
        return response.data.data;
    }
}