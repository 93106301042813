import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]" }
const _hoisted_3 = { class: "rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative" }
const _hoisted_4 = { class: "lg:w-[35%] w-[90%] mx-auto mt-[40px]" }
const _hoisted_5 = { class: "border-[1px] border-grey-50 rounded-lg" }
const _hoisted_6 = { class: "flex items-center gap-[12px] py-[12px] w-fit mx-auto" }
const _hoisted_7 = { class: "p-[10px]" }
const _hoisted_8 = { class: "lg:text-heading-3 text-header-4" }
const _hoisted_9 = { class: "p-[10px]" }
const _hoisted_10 = { class: "lg:text-heading-3 text-header-4" }
const _hoisted_11 = { class: "p-[10px]" }
const _hoisted_12 = { class: "lg:text-heading-3 text-header-4" }
const _hoisted_13 = { class: "p-[10px]" }
const _hoisted_14 = { class: "lg:text-heading-3 text-header-4" }
const _hoisted_15 = { class: "mt-[24px]" }
const _hoisted_16 = { class: "text-grey-200" }
const _hoisted_17 = {
  key: 0,
  class: "flex justify-center items-center py-[16px]"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex text-body-2 border-[0.5px] border-grey-50" }
const _hoisted_20 = { class: "w-[70%] py-[16px] px-[24px]" }
const _hoisted_21 = { class: "flex text-body-2 border-[0.5px] border-grey-50" }
const _hoisted_22 = { class: "w-[70%] py-[16px] px-[24px]" }
const _hoisted_23 = { class: "flex text-body-2 border-[0.5px] border-grey-50" }
const _hoisted_24 = { class: "w-[70%] py-[16px] px-[24px]" }
const _hoisted_25 = { class: "flex text-body-2 border-[0.5px] border-grey-50" }
const _hoisted_26 = { class: "w-[70%] py-[16px] px-[24px]" }
const _hoisted_27 = { class: "flex text-body-2 border-[0.5px] border-grey-50" }
const _hoisted_28 = { class: "w-[70%] py-[16px] px-[24px]" }
const _hoisted_29 = { class: "my-[20px] flex items-center justify-end gap-[10px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import router from '@/router';
import PaymentService from '@/utility/services/microsite/payment.service';
import StoreService from '@/utility/services/microsite/store.service';
import { Icon } from '@iconify/vue';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'BankTransfer',
  setup(__props) {

const bankData = ref();
const countdown = ref({ days: 0, hours: 0, minutes: 0, seconds: 0 });
const loading = ref(true);

const user = ref<any>(JSON.parse(localStorage.getItem('user') || 'null')); 

watch(user, (newValue) => {
    if (newValue) {
        localStorage.setItem('user', JSON.stringify(newValue)); 
    }
});

watch(
    () => localStorage.getItem('user'),
    (newValue) => {
        user.value = JSON.parse(newValue || 'null'); 
    }
);

const route = useRoute();

onMounted(async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params;

    const cartData = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
    const totalPrice = parseFloat(cartData.totalPrice.replace(/₦|,/g, ''));

    console.log({cartData})

    const data = {
        orderId,
        orderChannel: "Microsite",
        payment: {
            firstName: user.value.firstName,
            lastName: user.value.lastName,
            email: user.value.email,
            phone: user.value.phone,
            amount: totalPrice,
            paymentOption: "BankTransfer",
        }
    }
    try {
        bankData.value = await PaymentService.initiate(`${storeId}`, data);
        startCountdown(bankData.value.meta.authorization.account_expiration);
    } catch (error) {
        console.error("Error fetching bank data:", error);
    } finally {
        loading.value = false;
    }
});

function startCountdown(expirationTime: any) {
    const expirationDate = new Date(expirationTime).getTime();
    const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = expirationDate - now;

        if (distance < 0) {
            clearInterval(interval);
            countdown.value = { days: 0, hours: 0, minutes: 0, seconds: 0 };
            return;
        }

        countdown.value.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        countdown.value.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        countdown.value.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        countdown.value.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }, 1000);
}

const verifyPayment = () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params; 

    router.push({
        path: `/verify_payment/${bankData?.value?.meta?.authorization?.transfer_reference}/${orderId}`,
        query: { storeId, restaurantId } 
    })
}

console.log(bankData.value);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(router).back && _unref(router).back(...args))),
        class: "cursor-pointer"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:arrow-left-outlined",
            width: "18",
            height: "18",
            class: "text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-medium text-sub-heading" }, "Back to checkout", -1))
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "bg-[#D9D9D966] py-[12px] text-center rounded-t-lg" }, "THIS ACCOUNT WILL EXPIRE IN", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(countdown.value.days), 1),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200" }, "DAYS", -1))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "" }, ":", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(countdown.value.hours), 1),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200" }, "HOURS", -1))
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "" }, ":", -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(countdown.value.minutes), 1),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200" }, "MIN", -1))
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "" }, ":", -1)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(countdown.value.seconds), 1),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200" }, "SEC", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "bg-link py-[16px] text-white text-center rounded-t-lg" }, "To complete your order, please make bank transfer to the account below", -1)),
        _createElementVNode("div", _hoisted_16, [
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-grey-100" }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]" }, "Transfer reference", -1)),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(bankData.value?.meta?.authorization?.transfer_reference), 1)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]" }, "Account Number", -1)),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(bankData.value?.meta?.authorization?.transfer_account), 1)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]" }, "Bank Name", -1)),
                  _createElementVNode("div", _hoisted_24, _toDisplayString(bankData.value?.meta?.authorization?.transfer_bank), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]" }, "Transfer Note", -1)),
                  _createElementVNode("div", _hoisted_26, _toDisplayString(bankData.value?.meta?.authorization?.transfer_note), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "w-[30%] bg-bg-200 border-r-[0.5px] border-grey-50 py-[16px] px-[24px]" }, "Transfer Amount", -1)),
                  _createElementVNode("div", _hoisted_28, "₦" + _toDisplayString(parseFloat(bankData.value?.meta?.authorization?.transfer_amount).toLocaleString()), 1)
                ])
              ]))
        ])
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-grey-300 my-[20px] font-bold" }, "After you've made Payment, please click on the verify button for your payment to be confirmed.", -1)),
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-error font-bold" }, "Please do not refresh this page.", -1)),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(ActionButton, {
          label: "Cancel",
          theme: "outlined",
          class: "w-fit",
          handleClick: _unref(router).back
        }, null, 8, ["handleClick"]),
        _createVNode(ActionButton, {
          label: "Verify",
          class: "bg-error text-white w-fit",
          handleClick: () => verifyPayment()
        }, null, 8, ["handleClick"])
      ])
    ])
  ]))
}
}

})