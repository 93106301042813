import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between lg:px-[24px] lg:py-[18px] px-[16px] py-[12px] w-[100%] relative" }
const _hoisted_2 = { class: "flex items-center gap-[6px]" }
const _hoisted_3 = { class: "lg:flex items-center text-grey-200 text-body-2 font-medium lg:block hidden" }
const _hoisted_4 = { class: "w-fit lg:block hidden" }
const _hoisted_5 = {
  key: 0,
  class: "absolute top-full left-0 w-full bg-white text-white z-10 mt-2 py-[12px] rounded-lg shadow-lg"
}
const _hoisted_6 = { class: "flex flex-col items-start text-grey-200 text-body-2 font-medium" }

import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import ActionButton from '../action-button/ActionButton.vue';
import ImageCard from '../image/ImageCard.vue';

import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppNavbar',
  props: {
    activeLink: {}
  },
  setup(__props: any) {



const isDropdownOpen = ref(false); 
const router = useRouter();

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value; 
};

const goToFaq = () => {
    router.push('/').then(() => {
        const faqSection = document.getElementById('faq');
        if (faqSection) {
            faqSection.scrollIntoView({ behavior: 'smooth' });
        }
    });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(ImageCard, {
              imageUrl: require('@/assets/images/logos/foodcart.png'),
              width: "48",
              height: "48"
            }, null, 8, ["imageUrl"]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/' }])
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_router_link, {
          to: "/restaurants",
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === 'restaurants' }])
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Restaurants")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_router_link, {
          to: "/products",
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/products' }])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Products")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_router_link, {
          to: "/blog",
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/blog' }])
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Blog")
          ])),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("a", {
          href: "javascript:void(0)",
          onClick: goToFaq,
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/faq' }])
        }, "FAQ", 2),
        _createVNode(_component_router_link, {
          to: "/about-us",
          class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/about-us' }])
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("About Us")
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, { to: "/registration" }, {
          default: _withCtx(() => [
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Register Now",
              class: "rounded-[99.9px]"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: "border-[1px] border-grey-50 p-[10px] rounded-[8px] lg:hidden",
        onClick: toggleDropdown
      }, [
        _createVNode(_unref(Icon), {
          icon: "ant-design:menu-outlined",
          width: "18",
          height: "18"
        })
      ])
    ]),
    (isDropdownOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/",
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/' }])
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Home")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/restaurants",
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === 'restaurants' }])
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Restaurants")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/products",
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/products' }])
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Products")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/blog",
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/blog' }])
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Blog")
              ])),
              _: 1
            }, 8, ["class"]),
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: goToFaq,
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/faq' }])
            }, "FAQ", 2),
            _createVNode(_component_router_link, {
              to: "/about-us",
              class: _normalizeClass(["px-[24px] py-[8px]", { 'text-grey-300 underline': _ctx.activeLink === '/about-us' }])
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("About Us")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/registration",
              class: "px-[10px] mt-5"
            }, {
              default: _withCtx(() => [
                _createVNode(ActionButton, {
                  theme: "primary",
                  label: "Register Now",
                  class: "rounded-[99.9px]"
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})