export const endpoint = {
    stores: 'microsite/stores',
    store_menus: '/microsite/:restaurantId/store/:storeId/menus',
    store_categories: '/microsite/:restaurantId/store/:storeId/category',
    cart: '/microsite/user/:userId/cart',
    removeCart: '/microsite/user/:userId/cart/:menuId',
    store: '/microsite/:restaurantId/stores/:storeId',
    orderById: '/microsite/:restaurantId/store/:storeId/orders/:orderId',
    checkout: '/microsite/checkout/:storeId',
    initiate_payment: '/microsite/payment/stores/:storeId/initiate',
    verify_payment: '/microsite/verify/:transactionId/:storeId/:orderId',
    store_orders: '/microsite/:restaurantId/store/:storeId/customers/:customerId/orders'
}