import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[6px] absolute p-[20px]" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center h-screen text-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "text-lg text-gray-700 mb-6" }

import ImageCard from '@/components/shared/image/ImageCard.vue';
import { formatPrice } from '@/utility/helper/utils';
import PaymentService from '@/utility/services/microsite/payment.service';
import StoreService from '@/utility/services/microsite/store.service';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import cartService from '@/utility/services/microsite/cart.service';
import { useStore } from 'vuex';
import { getAuthInformation } from '../restaurants/restaurant.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'VerifyPayment',
  setup(__props) {

const loading = ref(true);
const paymentVerified = ref()
const cartData = ref();
const totalPrice = ref()
const route = useRoute();
const { orderId, transactionId } = route?.params; 
const { restaurantId, storeId } = route?.query
const { userData } = getAuthInformation()

const store = useStore();

console.log({cartData, userData})

const clearCart = async () => {
    try {
        const userId = userData.id;
        
        for (const menu of cartData.value.orderDetails) {
            await cartService.removeFromCart(userId, menu.menuId);
        }
        
        console.log("Cart cleared successfully.");
    } catch (error) {
        console.error("Error clearing cart:", error);
    }
};

onMounted(async () => {
    try {
        cartData.value = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
        paymentVerified.value = await PaymentService.verify(`${transactionId}`, `${storeId}`, `${orderId}`);
        totalPrice.value = parseFloat(cartData.value?.totalPrice.replace(/₦|,/g, ''));

        if (totalPrice.value) {
            await clearCart();
        }
    } catch (error) {
        console.error("Error fetching verified data:", error);
    } finally {
        loading.value = false;
    }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/logos/foodcart.png'),
            width: "48",
            height: "48"
          }, null, 8, ["imageUrl"]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ImageCard, {
          imageUrl: require('@/assets/images/icons/Check.png'),
          width: "80",
          height: "80"
        }, null, 8, ["imageUrl"])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "text-3xl font-bold text-green-600 mb-4 mt-[32px]" }, "Payment Successful!", -1)),
      _createElementVNode("p", _hoisted_4, [
        _createTextVNode("Your payment of " + _toDisplayString(_unref(formatPrice)(totalPrice.value)) + " has ben sent to FOODCART ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _cache[2] || (_cache[2] = _createTextVNode("TECHNOLOGY SERVICES LIMITED FLW"))
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-body-2 text-error text-center my-[30px]" }, "Redirecting...", -1)),
      _createVNode(_component_router_link, {
        to: "/restaurants",
        class: "px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500 transition duration-300 bg-primary-300"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Go to Home")
        ])),
        _: 1
      })
    ])
  ], 64))
}
}

})