import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex lg:px-[60px] py-[18px] border-b-[1px] justify-between" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex items-center gap-[6px] w-fit px-[20px] py-[3px]" }
const _hoisted_5 = {
  class: "grid items-center gap-[10px]",
  style: {"grid-template-columns":"1fr auto auto"}
}
const _hoisted_6 = { class: "lg:block hidden" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "lg:block hidden relative" }
const _hoisted_9 = {
  key: 0,
  class: "absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50"
}
const _hoisted_10 = { class: "flex justify-between gap-[8px] px-[16px] mb-[10px]" }
const _hoisted_11 = { class: "px-[16px] mt-[20px]" }
const _hoisted_12 = {
  key: 1,
  class: "absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50 w-[300px]"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "lg:hidden" }
const _hoisted_15 = {
  key: 0,
  class: "absolute top-[90px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50 w-full"
}
const _hoisted_16 = {
  key: 0,
  class: "flex flex-col items-left gap-[8px] px-[16px] mb-[10px]"
}
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col items-left gap-[8px] px-[16px] mb-[10px]"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "cursor-pointer" }
const _hoisted_20 = {
  class: "mt-[80px] grid",
  style: {"grid-template-rows":"auto 1fr auto"}
}
const _hoisted_21 = { class: "p-[24px] text-center text-grey-200" }
const _hoisted_22 = { class: "overflow-y-auto" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "flex gap-[8px]" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "font-semibold" }
const _hoisted_27 = { class: "text-caption text-grey-200" }
const _hoisted_28 = { class: "flex items-center justify-between" }
const _hoisted_29 = { class: "text-body-2 font-medium" }
const _hoisted_30 = { class: "bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "flex items-center justify-between p-[24px]" }
const _hoisted_36 = { class: "font-bold" }
const _hoisted_37 = { class: "border-t-[1px] border-solid border-grey-50 px-[24px] py-[24px]" }
const _hoisted_38 = { class: "lg:flex gap-[16px] lg:w-[1000px] m-auto pt-[63px] pb-[50px] relative" }
const _hoisted_39 = { class: "" }
const _hoisted_40 = { class: "relative text-white" }
const _hoisted_41 = { class: "text-heading-4 font-bold lg:text-left text-center" }
const _hoisted_42 = { class: "text-caption flex gap-[8px] items-center w-fit mx-auto lg:mx-0" }
const _hoisted_43 = { class: "mb-4 text-body-2 lg:text-left text-center lg:px-0 px-[16px] lg:whitespace-normal truncate" }
const _hoisted_44 = { class: "lg:flex items-center text-caption gap-[8px] w-fit mx-auto lg:mx-0" }
const _hoisted_45 = { class: "px-[13px] pb-[24px] relative lg:block hidden" }
const _hoisted_46 = { class: "pt-[32px] w-[100%]" }
const _hoisted_47 = { class: "flex lg:gap-[24px] gap-[10px] border-b-[1px] lg:px-[60px] font-medium text-grey-100 text-body-2" }
const _hoisted_48 = { class: "bg-[#f0f0f0]" }
const _hoisted_49 = {
  key: 0,
  class: "grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] px-[10px] py-[32px]"
}
const _hoisted_50 = {
  key: 1,
  class: "grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] px-[10px] py-[32px]"
}
const _hoisted_51 = ["onClick"]
const _hoisted_52 = { class: "font-semibold mt-[12px]" }
const _hoisted_53 = { class: "my-[12px] text-caption text-grey-200" }
const _hoisted_54 = { class: "modal-overlay" }
const _hoisted_55 = { class: "modal-conten w-[550px] h-full overflow-auto relative no-scrollbar" }
const _hoisted_56 = { class: "bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_57 = { class: "flex justify-between items-center" }
const _hoisted_58 = { class: "grid gap-[8px] mt-[16px]" }
const _hoisted_59 = { class: "flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]" }
const _hoisted_60 = { class: "text-caption text-grey-200 underline" }
const _hoisted_61 = { class: "modal-overlay" }
const _hoisted_62 = { class: "modal-conten w-[550px] h-full overflow-auto relative no-scrollbar" }
const _hoisted_63 = { class: "bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_64 = { class: "flex justify-between items-center" }
const _hoisted_65 = { class: "grid gap-[8px] my-[16px]" }
const _hoisted_66 = { class: "grid grid-cols-2 items-center gap-[16px]" }
const _hoisted_67 = { class: "flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]" }
const _hoisted_68 = { class: "text-caption text-grey-200 underline" }
const _hoisted_69 = { class: "relative" }
const _hoisted_70 = { class: "flex items-center justify-between lg:px-[32px] px-[10px] pt-[24px]" }
const _hoisted_71 = { class: "font-semibold text-heading-4" }
const _hoisted_72 = { class: "text-body-2 font-medium text-grey-300" }
const _hoisted_73 = { class: "text-grey-200 font-medium border-b-[1px] border-solid border-grey-50 py-[24px] lg:px-[32px] px-[10px]" }
const _hoisted_74 = { class: "flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]" }
const _hoisted_75 = { class: "bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]" }
const _hoisted_76 = { class: "" }
const _hoisted_77 = { class: "flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]" }
const _hoisted_78 = { class: "text-body-2 font-medium" }
const _hoisted_79 = { class: "flex items-center gap-[16px] border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] py-[24px] mt-[24px]" }
const _hoisted_80 = { class: "mt-[100px] flex items-center mx-[20px] text-body-2 gap-[32px] border-b-[1px] border-grey-50 text-grey-200" }
const _hoisted_81 = { class: "flex gap-[8px] p-[10px]" }
const _hoisted_82 = { class: "" }
const _hoisted_83 = { class: "font-semibold" }
const _hoisted_84 = { class: "text-caption text-grey-200" }
const _hoisted_85 = { class: "bg-grey-50 py-[4px] px-[8px] flex items-center justify-between text-caption rounded-b-[8px]" }
const _hoisted_86 = {
  key: 0,
  class: "text-grey-200"
}

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import SideModel from '@/components/shared/modal/SideModel.vue';
import StarRating from '@/components/shared/ratings/StarRating.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { Icon } from '@iconify/vue';
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { getRestaurantMenu, useSignup, useLogin, getAuthInformation, useLogout } from './restaurant.helper';
import { formatPrice } from '@/utility/helper/utils'
import cartService from '@/utility/services/microsite/cart.service';
import { useRoute, useRouter } from 'vue-router';
import CheckoutService from '@/utility/services/microsite/checkout.service';
import { useStore } from 'vuex';

interface CartItem extends Record<string, string | number> {
    imageUrl: string;
    name: string;
    price: string;
    quantity: number;
    totalPrice: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RestaurantView',
  setup(__props) {

const { token, userData } = getAuthInformation()
const signupData = ref();
const loginData = ref();
const signupLoading = ref(false);
const loginLoading = ref(false);
const storeX = useStore()

const isDropdownOpen = ref(false);
const isLoginModalOpen = ref(false);
const isSignUpModalOpen = ref(false);

const openLoginModal = () => {
    closeDropdown();
    isLoginModalOpen.value = true;
    isMobileMenuOpen.value = false;
    isSignUpModalOpen.value = false;
};

const openSignUpModal = () => {
    closeDropdown();
    isSignUpModalOpen.value = true;
    isLoginModalOpen.value = false;
    isMobileMenuOpen.value = false;
};

const closeDropdown = () => {
    isDropdownOpen.value = false;
};

const router = useRouter();
const route = useRoute();

const goToContactUS = () => {
    router.push('/').then(() => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    });
};

const checkout = async (user?: any) => {
    const { storeId, restaurantId } = route?.params; 

    try {
        if (user?.id || userData?.id) {
            const checkoutResponse = await CheckoutService.checkout(`${storeId}`, { orders: [{orderDetails: [...cart.value]}] });
        
            if (checkoutResponse && cart.value.length > 0) {
                router.push({ 
                    path: `/checkout/${checkoutResponse.orderId}`, 
                    query: { storeId, restaurantId } 
                });
            }
        } else {
            openSignUpModal()
        }
        
    } catch (error) {
        console.error("Checkout error:", error);
    }
}

const { signupFormHandler, signupResponseData } = useSignup(storeX, openLoginModal);
const { loginFormHandler } = useLogin(storeX, {callback: {checkout: checkout}})


const handleSignupSubmit = async (event: Event) => {
    event.preventDefault();
    signupLoading.value = true

    try {
        await signupFormHandler.handleSubmit(event);
        signupData.value = signupFormHandler.values;

        console.log({signupResponseData});

        signupLoading.value = false
    } catch (error) {
        console.log(error)
        return;
    }
};


const handleLoginSubmit = async (event: Event) => {
    event.preventDefault();
    loginLoading.value = true;

    try { 
        await loginFormHandler.handleSubmit(event);
        loginData.value = loginFormHandler.values;
        loginLoading.value = false;
    } catch (error) {
        console.log(error);
        return loginLoading.value = false; 
    }
};


const { storeMenu, loading, store, categories, selectedCategory } = getRestaurantMenu();
console.log({categories})

const isModalOpen = ref(false);
const selectedMenuDetails = ref();


const isSideModalOpen = ref(false);

const openSideModal = () => {
    isSideModalOpen.value = true; 
};

const closeSideModal = () => {
    isSideModalOpen.value = false; 
};

const cleanPrice = (price: string | number | undefined | null): number => {
    if (price === undefined || price === null) {
        console.error("Price is undefined or null. Defaulting to 0.");
        return 0;
    }

    const priceString = typeof price === 'number' ? price.toString() : price;

    const cleanedPrice = priceString.replace(/[^0-9.-]+/g, "");

    return parseFloat(cleanedPrice);
};

const openModal = (menus: any) => {
    console.log("Menu Object:", menus);
    selectedMenuDetails.value = {
        ...menus,
        quantity: 1, 
        price: menus.price ? cleanPrice(menus.price) : 0, 
        totalPrice: menus.price ? cleanPrice(menus.price) : 0, 
    };
    console.log("Selected Menu Details:", selectedMenuDetails.value);
    isModalOpen.value = true;
};

const imageStyle = ref({
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const getBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const getCartBackgroundStyle = (imageUrl: string, height?: number, width?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: `${width}px` || '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

watch(store, (newStore) => {
    console.log({newStore});
    
    if (newStore) {
        imageStyle.value.backgroundImage = `url(${newStore.cover})`;
    }
}, { immediate: true });

const isFixed = ref(false);
const navBar = ref<HTMLElement | null>(null);
let navBarOffsetTop = 0;

const handleScroll = () => {
    if (navBar.value) {
        const currentScroll = window.scrollY;
        isFixed.value = currentScroll >= navBarOffsetTop;
    }
};

onMounted(() => {
    if (navBar.value) {
        navBarOffsetTop = navBar.value.offsetTop; 
    }
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});


const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedMenuDetails.value = null; 
};


const isOrdersModalOpen = ref(false);
const currentTab = ref('Ongoing'); 

const orderStatuses = {
    Ongoing: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
    ],
    Delivered: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Delivered',
            deliveryDate: 'Delivered on: 04 July, 2024',
            receipt: 'Download receipt'
        }
    ],
    Cancelled: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled - Payment Unsuccessful',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        }
    ]
};

const changeTab = (tab: string) => {
    currentTab.value = tab;
};

const openOrdersModal = () => {
    isOrdersModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeOrdersModal = () => {
    isOrdersModalOpen.value = false;
};

const logout = async () => {
    await useLogout(() => {
        window.location.reload();
    });
};

const dropdownItems = [
    { icon: 'ant-design:shopping-outlined', text: 'Orders', /* action: openOrdersModal, */ disabled: true },
    { icon: 'ant-design:bell-outlined', text: 'Notifications', disabled: true },
    { icon: 'ant-design:history-outlined', text: 'Recently Viewed', disabled: true },
    { icon: 'ant-design:gift-outlined', text: 'Rewards', disabled: true },
    { icon: 'ant-design:heart-outlined', text: 'Favorite Items', disabled: true },
    { icon: 'ant-design:logout-outlined', text: 'Logout', class: 'border-t-[1px] border-grey-50 text-error pt-[11px] -mb-[11px]', action: logout, disabled: false }
];

const getStatusColorClass = (status: any) => {
    switch (status) {
        case 'Shipped':
            return 'bg-yellow-500'; 
        case 'Waiting to be shipped':
            return 'bg-link text-white'; 
        case 'Delivered':
            return 'bg-success text-white'; 
        case 'Cancelled - Payment Unsuccessful':
            return 'bg-black text-white'; 
        case 'Cancelled':
            return 'bg-black text-white'; 
        default:
            return '';
    }
};

const cartItemsCount = ref(0);

const cart = ref<CartItem[]>([]);

const totalPrice = computed(() => {
    return cart.value.reduce((acc, item) => acc + item.totalPrice, 0);
});

onMounted(async () => {
    try {
       if (userData?.id) {
            const userId = userData?.id;
            const cartData = await cartService.getCart(userId);
            cart.value = cartData.cart.items || [];
            cartItemsCount.value = cart.value.length;
        } else if (localStorage.getItem('cartItems')) {
            const storageCartItems = ref();
            storageCartItems.value = JSON.parse(`${localStorage.getItem('cartItems')}`)
            cart.value = storageCartItems.value || [];
            cartItemsCount.value = cart.value.length;
        }
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
});

const removeFromCart = async (menuId: string | number) => {
    try {
        const userId = userData?.id;
        console.log({ menuId });

        if (userId) {
            await cartService.removeFromCart(userId, menuId);

            const itemIndex = cart.value.findIndex(item => item.menuId === menuId);
            if (itemIndex > -1) {
                cart.value.splice(itemIndex, 1);
                cartItemsCount.value = cart.value.length;
            }
        } else {
            const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');

            if (existingCart.length < 1) {
                localStorage.removeItem("cartItems")
            }
            const updatedCart = existingCart.filter((item: any) => item.menuId !== menuId);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            cart.value = updatedCart; 
            cartItemsCount.value = cart.value.length;

            
        }
    } catch (error) {
        console.error("Error removing item from cart:", error);
    }
};

const incrementQuantity = async (item: CartItem) => {
    const price = cleanPrice(item.price);
    if (isNaN(price)) {
        console.error("Invalid price value:", item.price);
        return;
    }

    item.quantity += 1;
    item.totalPrice = price * item.quantity;

    try {
        const userId = userData?.id;
        const cartItem = {
            menuId: item.menuId,
            price: item.price,
            quantity: 1, 
            totalPrice: item.totalPrice,
            imageUrl: item.imageUrl,
            name: item.name,
            ingredient: item.ingredient,
        };

        const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
        const existingItem = existingCart.find((cart: CartItem) => cart.menuId === item.menuId);

        if (existingItem) {
            existingItem.quantity += 1; 
            existingItem.totalPrice = cleanPrice(existingItem.price) * existingItem.quantity;
            localStorage.setItem('cartItems', JSON.stringify(existingCart)); 
        }

        await cartService.addToCart(userId, [cartItem]); 
    } catch (error) {
        console.error("Error adding to cart:", error);
    }
};

const decrementQuantity = async (item: CartItem) => {
    if (item.quantity > 1) {
        const price = cleanPrice(item.price);
        if (isNaN(price)) {
            console.error("Invalid price value:", item.price);
            return;
        }

        item.quantity -= 1;
        item.totalPrice = price * item.quantity;

        try {
            const userId = userData?.id;
            const cartItem = {
                menuId: item.menuId,
                price: item.price,
                quantity: -1, 
                totalPrice: item.totalPrice,
                imageUrl: item.imageUrl,
                name: item.name,
                ingredient: item.ingredient,
            };

            const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
            const existingItem = existingCart.find((cart: CartItem) => cart.menuId === item.menuId);

            if (existingItem) {
                existingItem.quantity -= 1;
                existingItem.totalPrice = cleanPrice(existingItem.price) * existingItem.quantity;
                localStorage.setItem('cartItems', JSON.stringify(existingCart)); // Update local storage
            }

            await cartService.addToCart(userId, [cartItem]);
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }
};

const addToCart = async () => {
    if (selectedMenuDetails.value) {
        const existingItem = cart.value.find(item => item.menuId === selectedMenuDetails.value.id);
        if (existingItem) {
            // console.warn("Item is already in the cart.");
            storeX.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Ops!', x_message: "Item is already in the cart", duration: 5000 });
            return;
        }

        const userId = userData?.id;
        const cartItem: any = {
            menuId: selectedMenuDetails.value.id,
            price: cleanPrice(selectedMenuDetails.value.price),
            quantity: selectedMenuDetails.value.quantity,
            totalPrice: selectedMenuDetails.value.totalPrice,
            imageUrl: selectedMenuDetails.value.imageUrl,
            name: selectedMenuDetails.value.name,
            ingredient: selectedMenuDetails.value.ingredient,
        };

        try {
            if (userData?.id) {
                await cartService.addToCart(userId, [cartItem]);
                cart.value.push(cartItem);
                cartItemsCount.value = cart.value.length;
                closeModal();
            } else {
                const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
                existingCart.push(cartItem);
                localStorage.setItem('cartItems', JSON.stringify(existingCart));
                cart.value.push(cartItem); // Update the local cart state
                cartItemsCount.value = cart.value.length;
                closeModal();
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }
};

const handleBuyNow = async() => {
    await addToCart().then(() => {
        checkout()
    })
}

const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const closeLoginModal = () => {
    isLoginModalOpen.value = false;
    isDropdownOpen.value = false;
};

const closeSignUpModal = () => {
    isSignUpModalOpen.value = false;
    isDropdownOpen.value = false;
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "48",
                height: "48"
              }, null, 8, ["imageUrl"]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(InputField, {
            type: "text",
            prefixIcon: "ant-design:search-outlined"
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(ActionButton, {
            theme: "primary",
            prefixIcon: "ant-design:shopping-filled",
            label: cartItemsCount.value.toString(),
            class: "w-fit whitespace-nowrap",
            handleClick: openSideModal
          }, null, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(ActionButton, {
            theme: "outlined",
            prefixIcon: "ant-design:user-outlined",
            label: "Account",
            class: "w-fit whitespace-nowrap",
            onClick: toggleDropdown
          }),
          (isDropdownOpen.value && !_unref(token))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(ActionButton, {
                    theme: "outlined",
                    label: "Login",
                    class: "whitespace-nowrap",
                    onClick: openLoginModal
                  }),
                  _createVNode(ActionButton, {
                    theme: "primary",
                    label: "Sign Up",
                    class: "whitespace-nowrap",
                    onClick: openSignUpModal
                  })
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "border-b-[1px] border-grey-50 relative" }, [
                  _createElementVNode("div", { class: "mx-auto mb-[-12px] bg-white px-[8px] w-fit" }, "or")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(ActionButton, {
                    theme: "outlined",
                    prefixIcon: "ant-design:google-outlined",
                    label: "Continue with Google",
                    class: "w-fit whitespace-nowrap"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (isDropdownOpen.value && _unref(token))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", null, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dropdownItems, (item, index) => {
                    return _createElementVNode("div", {
                      key: index,
                      class: _normalizeClass(['flex items-center gap-[8px] font-medium py-[11px] px-[24px]', item.class, item.disabled ? 'text-gray-200 cursor-not-allowed hidden' : 'text-grey-200 cursor-pointer']),
                      onClick: item.action
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: item.icon,
                        class: "text-sub-heading"
                      }, null, 8, ["icon"]),
                      _createElementVNode("div", null, _toDisplayString(item.text), 1)
                    ], 10, _hoisted_13)
                  }), 64))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(ActionButton, {
            theme: "outlined",
            prefixIcon: "ant-design:menu-outlined",
            label: "",
            class: "w-fit whitespace-nowrap",
            onClick: toggleMobileMenu
          })
        ])
      ]),
      (isMobileMenuOpen.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (!_unref(token))
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(ActionButton, {
                    theme: "outlined",
                    label: "Login",
                    class: "whitespace-nowrap",
                    onClick: openLoginModal
                  }),
                  _createVNode(ActionButton, {
                    theme: "primary",
                    label: "Sign Up",
                    class: "whitespace-nowrap",
                    onClick: openSignUpModal
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dropdownItems, (item, index) => {
                    return _createElementVNode("div", {
                      key: index,
                      class: _normalizeClass(["flex items-center gap-[8px] text-grey-200 font-medium py-[11px] px-[24px]", ['flex items-center gap-[8px] font-medium py-[11px] px-[24px]', item.class, item.disabled ? 'text-gray-200 cursor-not-allowed' : 'text-grey-200 cursor-pointer']]),
                      onClick: item.action
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: item.icon,
                        class: "text-sub-heading"
                      }, null, 8, ["icon"]),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(item.text), 1)
                    ], 10, _hoisted_18)
                  }), 64))
                ]))
          ]))
        : _createCommentVNode("", true),
      _createVNode(SideModel, {
        openModal: isSideModalOpen.value,
        onClose: closeSideModal,
        "header-content": "Shopping Cart"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_20, [
            _withDirectives(_createElementVNode("div", _hoisted_21, " Your cart is empty. Start adding items! ", 512), [
              [_vShow, cart.value.length == 0]
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "p-[24px]" }, "Order Summary", -1)),
            _createElementVNode("div", _hoisted_22, [
              (cart.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cart.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "rounded-[8px] cursor-pointer grid gap-[8px] border-b-[1px] border-solid border-grey-50 p-[24px]"
                      }, [
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", {
                            style: _normalizeStyle(getCartBackgroundStyle(item.imageUrl, 60, 60))
                          }, null, 4),
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, _toDisplayString(item.name), 1),
                            _createElementVNode("div", _hoisted_27, _toDisplayString(item.ingredient), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(formatPrice)(+item.price)), 1),
                          _createElementVNode("div", _hoisted_30, [
                            (item.quantity > 1)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer",
                                  onClick: ($event: any) => (decrementQuantity(item))
                                }, [
                                  _createVNode(_unref(Icon), {
                                    icon: "ant-design:minus-outlined",
                                    width: "18",
                                    height: "18",
                                    class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                                  })
                                ], 8, _hoisted_31))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: "bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer",
                                  onClick: ($event: any) => (removeFromCart(item.menuId))
                                }, [
                                  _createVNode(_unref(Icon), {
                                    icon: "ant-design:delete-outlined",
                                    width: "18",
                                    height: "18",
                                    class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                                  })
                                ], 8, _hoisted_32)),
                            _createElementVNode("div", _hoisted_33, _toDisplayString(item.quantity), 1),
                            _createElementVNode("div", {
                              class: "bg-white rounded-full w-[32px] h-[32px] relative",
                              onClick: ($event: any) => (incrementQuantity(item))
                            }, [
                              _createVNode(_unref(Icon), {
                                icon: "ant-design:plus-outlined",
                                width: "18",
                                height: "18",
                                class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                              })
                            ], 8, _hoisted_34)
                          ])
                        ])
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_35, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-caption text-grey-100" }, "Subtotal (excluding taxes):", -1)),
                      _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(formatPrice)(totalPrice.value)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createVNode(ActionButton, {
                theme: "primary",
                label: "Checkout",
                class: "whitespace-nowrap",
                handleClick: () => checkout(),
                disable: cart.value.length < 1
              }, null, 8, ["handleClick", "disable"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["openModal"])
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-70" }, null, -1)),
      _createElementVNode("div", _hoisted_38, [
        _createElementVNode("div", _hoisted_39, [
          _createVNode(ImageCard, {
            imageUrl: _unref(store)?.logo,
            width: "100",
            height: "100",
            class: "w-fit mx-auto rounded-full border-[2px] border-white overflow-hidden"
          }, null, 8, ["imageUrl"])
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(store)?.name), 1),
          _createElementVNode("div", _hoisted_42, [
            _createVNode(StarRating, { maxStars: 5 }),
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "" }, "3.0 (505 price)", -1))
          ]),
          _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(store)?.description), 1),
          _createElementVNode("div", _hoisted_44, [
            _createVNode(ActionButton, {
              label: _unref(store)?.isOpen ? 'Opened' : 'Closed',
              class: _normalizeClass(["w-fit whitespace-nowrap text-white text-caption w-fit mx-auto lg:mx-0 mb-2 lg:mb-0", _unref(store)?.isOpen ? 'bg-success' : 'bg-error'])
            }, null, 8, ["label", "class"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("a", {
          href: "javascript:void(0)",
          onClick: goToContactUS
        }, [
          _createVNode(ActionButton, {
            theme: "outlined",
            label: "Contact Us",
            class: "w-fit whitespace-nowrap ml-auto relative text-white border-white"
          })
        ])
      ])
    ], 4),
    _createElementVNode("main", _hoisted_46, [
      _createElementVNode("div", {
        ref_key: "navBar",
        ref: navBar,
        class: _normalizeClass([{ 'fixed-top': isFixed.value }, "whitespace-nowrap overflow-auto no-scrollbar"])
      }, [
        _createElementVNode("div", _hoisted_47, [
          _createVNode(_component_router_link, {
            to: `?category=`,
            class: _normalizeClass([{ 'border-b-[2px] border-primary-300 text-primary-300': !_unref(selectedCategory) }, "p-[16px] cursor-pointer"])
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode(" All ")
            ])),
            _: 1
          }, 8, ["class"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: `?category=${category?.category}`,
              key: index,
              class: _normalizeClass([{ 'border-b-[2px] border-primary-300 text-primary-300': _unref(selectedCategory) === category?.category }, "p-[16px] cursor-pointer"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(category?.category), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"]))
          }), 128))
        ])
      ], 2),
      _createElementVNode("div", _hoisted_48, [
        (_unref(loading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                return _createElementVNode("div", {
                  key: index,
                  class: "lg:p-[12px] p-[6px] rounded-[8px] cursor-default"
                }, _cache[14] || (_cache[14] = [
                  _createElementVNode("div", { class: "bg-gray-300 h-[200px] rounded-[8px] mb-[12px] animate-pulse" }, null, -1),
                  _createElementVNode("div", { class: "bg-gray-300 h-[20px] rounded-[4px] mb-[6px] animate-pulse" }, null, -1),
                  _createElementVNode("div", { class: "bg-gray-300 h-[16px] rounded-[4px] animate-pulse" }, null, -1)
                ]))
              }), 64))
            ]))
          : _createCommentVNode("", true),
        (!_unref(loading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(storeMenu), (menu, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "lg:p-[12px] p-[6px] rounded-[8px] cursor-pointer",
                  onClick: ($event: any) => (openModal(menu))
                }, [
                  _createElementVNode("div", {
                    style: _normalizeStyle(getBackgroundStyle(menu.imageUrl, 200))
                  }, null, 4),
                  _createElementVNode("div", _hoisted_52, _toDisplayString(menu.name), 1),
                  _createElementVNode("div", _hoisted_53, _toDisplayString(menu.price), 1)
                ], 8, _hoisted_51))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_54, [
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", _hoisted_57, [
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-grey-300 text-sub-heading" }, "Login", -1)),
              _createVNode(_unref(Icon), {
                icon: "ant-design:close-outlined",
                onClick: closeLoginModal,
                class: "cursor-pointer"
              })
            ]),
            _createElementVNode("form", {
              onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleLoginSubmit($event)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_58, [
                _createVNode(InputField, {
                  type: "email",
                  label: "email",
                  name: "email",
                  value: _unref(loginFormHandler).values.email,
                  errors: _unref(loginFormHandler).errors.email,
                  "onUpdate:blur:value": _unref(loginFormHandler).handleBlur,
                  "onUpdate:value": _unref(loginFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  type: "password",
                  label: "Password",
                  name: "password",
                  value: _unref(loginFormHandler).values.password,
                  errors: _unref(loginFormHandler).errors.password,
                  "onUpdate:blur:value": _unref(loginFormHandler).handleBlur,
                  "onUpdate:value": _unref(loginFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-right text-caption text-grey-200 underline mb-[16px] mt-[8px]" }, "Forget Password", -1)),
              _createVNode(ActionButton, {
                theme: "primary",
                label: "Login",
                class: "whitespace-nowrap mb-[16px]",
                isSubmit: true,
                disable: loginLoading.value
              }, null, 8, ["disable"])
            ], 32),
            _createElementVNode("div", _hoisted_59, [
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-caption text-grey-200 underline" }, "Don't have an account?", -1)),
              _createElementVNode("div", _hoisted_60, [
                _createVNode(ActionButton, {
                  theme: "outlined",
                  label: "Signup",
                  class: "whitespace-nowrap",
                  "handle-click": () => openSignUpModal()
                }, null, 8, ["handle-click"])
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, isLoginModalOpen.value]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_61, [
        _createElementVNode("div", _hoisted_62, [
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("div", _hoisted_64, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-grey-300 text-sub-heading" }, "Sign Up", -1)),
              _createVNode(_unref(Icon), {
                icon: "ant-design:close-outlined",
                onClick: closeSignUpModal,
                class: "cursor-pointer"
              })
            ]),
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleSignupSubmit($event)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_65, [
                _createElementVNode("div", _hoisted_66, [
                  _createVNode(InputField, {
                    type: "text",
                    label: "First Name",
                    name: "firstName",
                    value: _unref(signupFormHandler).values.firstName,
                    errors: _unref(signupFormHandler).errors.firstName,
                    "onUpdate:blur:value": _unref(signupFormHandler).handleBlur,
                    "onUpdate:value": _unref(signupFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "Last Name",
                    name: "lastName",
                    value: _unref(signupFormHandler).values.lastName,
                    errors: _unref(signupFormHandler).errors.lastName,
                    "onUpdate:blur:value": _unref(signupFormHandler).handleBlur,
                    "onUpdate:value": _unref(signupFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createVNode(InputField, {
                  type: "text",
                  label: "Email",
                  name: "email",
                  value: _unref(signupFormHandler).values.email,
                  errors: _unref(signupFormHandler).errors.email,
                  "onUpdate:blur:value": _unref(signupFormHandler).handleBlur,
                  "onUpdate:value": _unref(signupFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  type: "text",
                  label: "Phone Number",
                  name: "phone",
                  value: _unref(signupFormHandler).values.phone,
                  errors: _unref(signupFormHandler).errors.phone,
                  "onUpdate:blur:value": _unref(signupFormHandler).handleBlur,
                  "onUpdate:value": _unref(signupFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  type: "password",
                  label: "Password",
                  name: "password",
                  value: _unref(signupFormHandler).values.password,
                  errors: _unref(signupFormHandler).errors.password,
                  "onUpdate:blur:value": _unref(signupFormHandler).handleBlur,
                  "onUpdate:value": _unref(signupFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
              ]),
              _createVNode(ActionButton, {
                theme: "primary",
                label: "Sign Up",
                class: "whitespace-nowrap mb-[16px]",
                isSubmit: true,
                disable: signupLoading.value
              }, null, 8, ["disable"])
            ], 32),
            _createElementVNode("div", _hoisted_67, [
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-caption text-grey-200 underline" }, "Already have an account?", -1)),
              _createElementVNode("div", _hoisted_68, [
                _createVNode(ActionButton, {
                  theme: "outlined",
                  label: "Login",
                  class: "whitespace-nowrap",
                  "handle-click": () => openLoginModal()
                }, null, 8, ["handle-click"])
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, isSignUpModalOpen.value]
      ]),
      _createVNode(AppModal, {
        openModal: isModalOpen.value,
        onClose: closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", {
              style: _normalizeStyle(getBackgroundStyle(selectedMenuDetails.value?.imageUrl, 300))
            }, null, 4),
            _createElementVNode("div", {
              onClick: closeModal,
              class: "absolute right-[20px] top-[20px] bg-black opacity-70 w-[32px] h-[32px] rounded-[8px] border-[1px] border-white cursor-pointer"
            }, [
              _createVNode(_unref(Icon), {
                icon: "ant-design:close-outlined",
                width: "18",
                height: "18",
                class: "text-white absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_70, [
            _createElementVNode("div", _hoisted_71, _toDisplayString(selectedMenuDetails.value?.name), 1),
            _createElementVNode("div", _hoisted_72, _toDisplayString(_unref(formatPrice)(selectedMenuDetails.value?.price)), 1)
          ]),
          _createElementVNode("div", _hoisted_73, _toDisplayString(selectedMenuDetails.value?.description), 1),
          _createElementVNode("div", _hoisted_74, [
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "font-semibold text-body-2" }, "Quantity: ", -1)),
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("div", {
                class: "bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (decrementQuantity(selectedMenuDetails.value)))
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:minus-outlined",
                  width: "18",
                  height: "18",
                  class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                })
              ]),
              _createElementVNode("div", _hoisted_76, _toDisplayString(selectedMenuDetails.value?.quantity), 1),
              _createElementVNode("div", {
                class: "bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (incrementQuantity(selectedMenuDetails.value)))
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:plus-outlined",
                  width: "18",
                  height: "18",
                  class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                })
              ])
            ])
          ]),
          _createVNode(TextArea, {
            label: "Add a note",
            placeholder: "Add a note to your order (optional)",
            class: "lg:px-[32px] px-[10px]"
          }),
          _createElementVNode("div", _hoisted_77, [
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "font-semibold text-body-2" }, "Total Price: ", -1)),
            _createElementVNode("div", _hoisted_78, _toDisplayString(_unref(formatPrice)(selectedMenuDetails.value?.totalPrice)), 1)
          ]),
          _createElementVNode("div", _hoisted_79, [
            _createVNode(ActionButton, {
              theme: "primary",
              label: `Add to Cart ${ _unref(formatPrice)(selectedMenuDetails.value?.totalPrice) }`,
              class: "whitespace-nowrap",
              onClick: addToCart
            }, null, 8, ["label"]),
            _createVNode(ActionButton, {
              label: `Buy Now ${ _unref(formatPrice)(selectedMenuDetails.value?.totalPrice) }`,
              class: "whitespace-nowrap bg-grey-50 text-grey-300",
              onClick: handleBuyNow
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["openModal"]),
      _createVNode(AppModal, {
        "header-content": "Orders",
        openModal: isOrdersModalOpen.value,
        onClose: closeOrdersModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_80, [
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Ongoing'}, "py-[12px] cursor-pointer"]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (changeTab('Ongoing')))
            }, "Ongoing", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Delivered'}, "cursor-pointer"]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (changeTab('Delivered')))
            }, "Delivered", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Cancelled'}, "cursor-pointer"]),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (changeTab('Cancelled')))
            }, "Cancelled/Returned", 2)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderStatuses[currentTab.value as keyof typeof orderStatuses], (order, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "rounded-[8px] cursor-pointer grid gap-[8px] border-[1px] border-solid border-grey-50 m-[20px]"
            }, [
              _createElementVNode("div", _hoisted_81, [
                _createElementVNode("div", {
                  style: _normalizeStyle(getCartBackgroundStyle(order.imageUrl, 84, 84))
                }, null, 4),
                _createElementVNode("div", _hoisted_82, [
                  _createElementVNode("div", _hoisted_83, _toDisplayString(order.name), 1),
                  _createElementVNode("div", _hoisted_84, _toDisplayString(order.ingredient), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass([getStatusColorClass(order.status), "px-[8px] py-[4px] rounded-[4px] w-fit text-caption mt-[14px]"])
                  }, _toDisplayString(order.status), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_85, [
                _createElementVNode("div", null, _toDisplayString(order.deliveryDate), 1),
                (currentTab.value != 'Cancelled' && 'receipt' in order)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                      _createVNode(_component_router_link, { to: "/receipt" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(order.receipt), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["openModal"])
    ])
  ]))
}
}

})