<template>
    <div>
        <div :style="imageStyle" class="lg:px-[60px] py-[24px] relative">
            <div class="absolute inset-0 bg-black opacity-50"></div>

            <div class="lg:px-[60px] px-[20px]">
                <div class="shadow-md rounded-[200px] bg-white relative">
                    <AppNavbar />
                </div>
            </div>
            
            <div class="font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[260px] mt-[150px] text-white font-bold relative">
                <div class="px-[60px] lg:flex justify-center gap-[20px]">
                    <div class="">Blog &</div>
                    <div class="bg-white text-primary-300 lg:px-[20px] rounded-[16px] ">Stories</div>
                </div>
            </div>

            <ImageCard :imageUrl="require('@/assets/images/icons/16.png')" width="140" height="140" class="absolute top-[200px] right-[60px] lg:block hidden" />
            <ImageCard :imageUrl="require('@/assets/images/icons/17.png')" width="140" height="140" class="absolute bottom-[50px] left-[60px] lg:block hidden" />

            <div class="px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 lg:mt-[40px] mt-[20px] text-[#FFFFFFBF] relative">
                The good stuff. Follow our social media for the latest updates, features and even sneak peeks 👀
            </div>

            <div class="w-fit mx-auto pt-[32px] relative">
                <router-link to="/registration">
                    <ActionButton theme="primary" label="Register Now" class="rounded-[99.9px]" />
                </router-link>
            </div>

            <!-- <div class="w-fit mx-[auto] flex items-center lg:gap-[20px] gap-[8px] mt-[32px] relative text-white">
                <a href="https://x.com/foodcartng" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:twitter-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.facebook.com/foodcart.tech" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:facebook-filled" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.youtube.com/channel/UCegkuRFbZMXD2rOJhtFg2Kg" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:youtube-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.instagram.com/foodcart.ng/" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:instagram-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>
            </div> -->
        </div>

        <main class="lg:px-[60px] mt-[60px] mb-[-30px]">
            <div class="whitespace-nowrap overflow-auto px-[20px] no-scrollbar">
                <div class="flex lg:gap-[24px] gap-[10px] mb-[32px] border-b-[1px] w-fit font-medium text-grey-100">
                    <div class="p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer">All</div>
                    <div class="p-[16px] cursor-not-allowed">News</div>
                    <div class="p-[16px] cursor-not-allowed">Marketings</div>
                    <div class="p-[16px] cursor-not-allowed">Vendors</div>
                    <div class="p-[16px] cursor-not-allowed">Operations</div>
                </div>
            </div>

            <div class="grid lg:grid-cols-4 lg:gap-[32px] gap-[20px] px-[20px]">
                <div v-for="(blog, index) in blogs" :key="index" class="border-[1px] border-grey-50 rounded-[8px]">
                    <div :style="getBackgroundStyle(blog.imageUrl)"></div>
                    <div class="p-[32px] text-center">
                        <div class="font-semibold mt-[12px] text-heading-4 truncate">{{ blog.name }}</div>
                        <div class="my-[20px] text-grey-200 h-[120px] overflow-hidden">{{ blog.location }}</div>
                        <div class="flex items-center gap-[10px] mt-[28px]">
                            <ActionButton theme="secondary" label="Read More" @click="openModal(blog)" />
                        </div>
                    </div>
                </div>
            </div>

            <AppModal :headerContent="selectedRestaurant?.name" :openModal="isModalOpen" @close="closeModal">
                <div class="px-[28px] pt-[32px]">
                    <p class="text-body-1 text-grey-200 whitespace-pre-wrap">{{ selectedRestaurant?.content }}</p>
                </div>
                <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                    <ActionButton label="Close" theme="secondary" @click="closeModal" class="w-fit" />
                </footer>
            </AppModal>
        </main>

        <!-- <AppFaq /> -->
        
        <div class="mt-[48px]">
            <AppFooter />    
        </div>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
// import { Icon } from '@iconify/vue';
import { blogs } from '@/views/blog/blog.helper'
import { ref } from 'vue';

const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fmedium-shot-woman-reading-coffee-shop_23-2150183723.jpg&f=1&nofb=1&ipt=da1acdcebe4df8352a14d44946aaf761e9eb44b275eb536b95efd8cbe24be3cd&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const isModalOpen = ref(false);
const selectedRestaurant = ref();

const openModal = (restaurant: any) => {
    selectedRestaurant.value = restaurant; 
    isModalOpen.value = true; 
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedRestaurant.value = null; 
};

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', // Adjust the height as per your design
    width: '100%',
    borderRadius: '8px 8px 0 0'
});

</script>
