import ApiService from './api.service';

export default class AuthService {
    
    static async registrationRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('/account/signup', { ...values })
        return response.data
    }

    static async signupRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('/microsite/signup', { ...values })
        return response.data
    }

    static async loginRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('/microsite/auth/login', { ...values })
        return response.data
    }

    static async logoutRequest(): Promise<unknown> {
        const response = await ApiService.post('/microsite/logout', {})
        return response.data
    }

    static async contactUsRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('/account/contact-us', { ...values })
        return response.data
    }

    static async categoriesRequest(): Promise<unknown> {
        const response = await ApiService.get('/restaurant/setup/category')
        return response.data.data
    }
}