<template>
    <router-link to="/">
        <div class="flex items-center gap-[6px] absolute p-[20px]">
            <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
            <div class="font-itim text-heading-5">Foodcart</div>
        </div>
    </router-link>

    <div class="flex flex-col items-center justify-center h-screen text-center">
        <div class="">
            <ImageCard :imageUrl="require('@/assets/images/icons/Check.png')" width="80" height="80" />
        </div>
        <h1 class="text-3xl font-bold text-green-600 mb-4 mt-[32px]">Payment Successful!</h1>
        <p class="text-lg text-gray-700 mb-6">Your payment of {{ formatPrice(totalPrice) }} has ben sent to FOODCART <br />TECHNOLOGY SERVICES LIMITED FLW</p>
        <div class="text-body-2 text-error text-center my-[30px]">Redirecting...</div>
        <router-link to="/restaurants" class="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500 transition duration-300 bg-primary-300">Go to Home</router-link>
    </div>
</template>

<script setup lang="ts">
import ImageCard from '@/components/shared/image/ImageCard.vue';
import { formatPrice } from '@/utility/helper/utils';
import PaymentService from '@/utility/services/microsite/payment.service';
import StoreService from '@/utility/services/microsite/store.service';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import cartService from '@/utility/services/microsite/cart.service';
import { useStore } from 'vuex';
import { getAuthInformation } from '../restaurants/restaurant.helper';

const loading = ref(true);
const paymentVerified = ref()
const cartData = ref();
const totalPrice = ref()
const route = useRoute();
const { orderId, transactionId } = route?.params; 
const { restaurantId, storeId } = route?.query
const { userData } = getAuthInformation()

const store = useStore();

console.log({cartData, userData})

const clearCart = async () => {
    try {
        const userId = userData.id;
        
        for (const menu of cartData.value.orderDetails) {
            await cartService.removeFromCart(userId, menu.menuId);
        }
        
        console.log("Cart cleared successfully.");
    } catch (error) {
        console.error("Error clearing cart:", error);
    }
};

onMounted(async () => {
    try {
        cartData.value = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
        paymentVerified.value = await PaymentService.verify(`${transactionId}`, `${storeId}`, `${orderId}`);
        totalPrice.value = parseFloat(cartData.value?.totalPrice.replace(/₦|,/g, ''));

        if (totalPrice.value) {
            await clearCart();
        }
    } catch (error) {
        console.error("Error fetching verified data:", error);
    } finally {
        loading.value = false;
    }
});
</script>