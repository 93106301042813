import ApiService from "../api.service";
import { endpoint } from "@/utility/endpoint";

export default class CartService {
    static async getCart(userId: string): Promise<any> {
        const url = endpoint.cart.replace(':userId', userId);
        const response = await ApiService.get(url);
        return response.data.data;
    }

    static async addToCart(userId: string, data: Array<Record<string, string | number>>): Promise<any> {
        const url = endpoint.cart.replace(':userId', userId);
        const response = await ApiService.post(url, data);
        return response.data.data;
    }

    static async removeFromCart(userId: string, menuId: any): Promise<any> {
        const url = endpoint.removeCart.replace(':userId', userId).replace(':menuId', menuId);
        const response = await ApiService.delete(url);
        return response.data.data;
    }
}