<template>
    <div class="">
        <header class="flex lg:px-[60px] py-[18px] border-b-[1px] justify-between" >
            <router-link to="/">
                <div class="">
                    <div class="flex items-center gap-[6px] w-fit px-[20px] py-[3px]">
                        <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
                        <div class="font-itim text-heading-5">Foodcart</div>
                    </div>
                </div>
            </router-link>

            <div class="grid items-center gap-[10px]" style="grid-template-columns: 1fr auto auto">
                <div class="lg:block hidden">
                    <InputField type="text" prefixIcon="ant-design:search-outlined" />
                </div>
                <div class="">
                    <ActionButton theme="primary" prefixIcon="ant-design:shopping-filled" :label="cartItemsCount.toString()" class="w-fit whitespace-nowrap" :handleClick="openSideModal" />
                </div>

                <div class="lg:block hidden relative">
                    <ActionButton theme="outlined" prefixIcon="ant-design:user-outlined" label="Account" class="w-fit whitespace-nowrap" @click="toggleDropdown" />

                    <!-- Unauthenticated -->
                    <div v-if="isDropdownOpen && !token" class="absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50">
                        <div class="flex justify-between gap-[8px] px-[16px]  mb-[10px]">
                            <ActionButton theme="outlined" label="Login" class="whitespace-nowrap" @click="openLoginModal" />
                            <ActionButton theme="primary" label="Sign Up" class="whitespace-nowrap" @click="openSignUpModal" />
                        </div>
                        <div class="border-b-[1px] border-grey-50 relative">
                            <div class="mx-auto mb-[-12px] bg-white px-[8px] w-fit">or</div>
                        </div>
                        <div class="px-[16px] mt-[20px]">
                            <ActionButton theme="outlined" prefixIcon="ant-design:google-outlined" label="Continue with Google" class="w-fit whitespace-nowrap" />
                        </div>
                    </div>

                    <!-- Authenticated -->
                    <div v-if="isDropdownOpen && token" class="absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50 w-[300px]">
                        <div>
                            <div v-for="(item, index) in dropdownItems" :key="index" 
                                :class="['flex items-center gap-[8px] font-medium py-[11px] px-[24px]', item.class, item.disabled ? 'text-gray-200 cursor-not-allowed hidden' : 'text-grey-200 cursor-pointer']" 
                                @click="item.action">
                                <Icon :icon="item.icon" class="text-sub-heading" />
                                <div>{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="lg:hidden">
                    <ActionButton theme="outlined" prefixIcon="ant-design:menu-outlined" label="" class="w-fit whitespace-nowrap" @click="toggleMobileMenu" />
                </div>
            </div>

            <!-- Mobile Menu -->
            <div v-if="isMobileMenuOpen" class="absolute top-[90px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50 w-full">
                <div v-if="!token" class="flex flex-col items-left gap-[8px] px-[16px] mb-[10px]">
                    <ActionButton theme="outlined" label="Login" class="whitespace-nowrap" @click="openLoginModal" />
                    <ActionButton theme="primary" label="Sign Up" class="whitespace-nowrap" @click="openSignUpModal" />
                </div>
                <div v-else class="flex flex-col items-left gap-[8px] px-[16px] mb-[10px]">
                    <div v-for="(item, index) in dropdownItems" :key="index" 
                        class="flex items-center gap-[8px] text-grey-200 font-medium py-[11px] px-[24px]" 
                        :class="['flex items-center gap-[8px] font-medium py-[11px] px-[24px]', item.class, item.disabled ? 'text-gray-200 cursor-not-allowed' : 'text-grey-200 cursor-pointer']" 
                        @click="item.action">
                        <Icon :icon="item.icon" class="text-sub-heading " />
                        <div class="cursor-pointer">{{ item.text }}</div>
                    </div>
                </div>
            </div>

            <SideModel :openModal="isSideModalOpen" @close="closeSideModal" header-content="Shopping Cart">
                <div class="mt-[80px] grid" style="grid-template-rows: auto 1fr auto;">
                    <div class="p-[24px] text-center text-grey-200" v-show="cart.length == 0">
                        Your cart is empty. Start adding items!
                    </div>
                    <div class="p-[24px]">Order Summary</div>
                    <div class="overflow-y-auto">
                        <div v-if="cart.length > 0">
                            <div v-for="(item, index) in cart" :key="index" class="rounded-[8px] cursor-pointer grid gap-[8px] border-b-[1px] border-solid border-grey-50 p-[24px]">
                                <div class="flex gap-[8px]">
                                    <div :style="getCartBackgroundStyle(item.imageUrl, 60, 60)"></div>
                                    <div class="">
                                        <div class="font-semibold">{{ item.name }}</div>
                                        <div class="text-caption text-grey-200">{{ item.ingredient }}</div>
                                    </div>
                                </div>

                                <div class="flex items-center justify-between">
                                    <div class="text-body-2 font-medium">{{ formatPrice(+item.price) }}</div>
                                    <div class="bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]">
                                        <div v-if="item.quantity > 1" class="bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer" @click="decrementQuantity(item)">
                                            <Icon icon="ant-design:minus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                        </div>
                                        <div v-else class="bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer" @click="removeFromCart(item.menuId)">
                                            <Icon icon="ant-design:delete-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                        </div>
                                        <div class="">{{ item.quantity }}</div>
                                        <div class="bg-white rounded-full w-[32px] h-[32px] relative" @click="incrementQuantity(item)">
                                            <Icon icon="ant-design:plus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="flex justify-end">
                                    <ActionButton theme="secondary" label="Remove" class="w-fit whitespace-nowrap bg-error" @click="removeFromCart(item.menuId)" />
                                </div> -->
                            </div>

                            <div class="flex items-center justify-between p-[24px]">
                                <div class="text-caption text-grey-100">Subtotal (excluding taxes):</div>
                                <div class="font-bold">{{ formatPrice(totalPrice) }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="border-t-[1px] border-solid border-grey-50 px-[24px] py-[24px]">
                        <!-- <router-link to="/checkout"> -->
                            <ActionButton theme="primary" label="Checkout" class="whitespace-nowrap" :handleClick="() => checkout()" :disable="cart.length < 1" />
                        <!-- </router-link> -->
                    </div>
                </div>
            </SideModel>
        </header>

        <div :style="imageStyle" class="lg:px-[60px] py-[24px] relative">
            <div class="absolute inset-0 bg-black opacity-70"></div>

            <div class="lg:flex gap-[16px] lg:w-[1000px] m-auto pt-[63px] pb-[50px] relative">
                <div class="">
                    <ImageCard :imageUrl="store?.logo" width="100" height="100" class="w-fit mx-auto rounded-full border-[2px] border-white overflow-hidden" />
                </div>

                <div class="relative text-white">
                    <div class="text-heading-4 font-bold lg:text-left text-center">{{ store?.name }}</div>
                    <div class="text-caption flex gap-[8px] items-center w-fit mx-auto lg:mx-0">
                        <StarRating :maxStars="5" />
                        <div class="">3.0 (505 price)</div>
                    </div>
                    <div class="mb-4 text-body-2 lg:text-left text-center lg:px-0 px-[16px] lg:whitespace-normal truncate">
                        {{ store?.description }}
                    </div>
                    <div class="lg:flex items-center text-caption gap-[8px] w-fit mx-auto lg:mx-0">
                        <ActionButton :label="store?.isOpen ? 'Opened' : 'Closed'" class="w-fit whitespace-nowrap text-white text-caption w-fit mx-auto lg:mx-0 mb-2 lg:mb-0" :class="store?.isOpen ? 'bg-success' : 'bg-error'" />
                        <!-- <div>Opening time: 7:00 AM to 11:00 PM</div> -->
                    </div>
                </div>
            </div>

            <div class="px-[13px] pb-[24px] relative lg:block hidden">
                <a href="javascript:void(0)" @click="goToContactUS"><ActionButton theme="outlined" label="Contact Us" class="w-fit whitespace-nowrap ml-auto relative text-white border-white" /></a>
            </div>
        </div>

        <main class="pt-[32px] w-[100%]">
            <div ref="navBar" :class="{ 'fixed-top': isFixed }" class="whitespace-nowrap overflow-auto no-scrollbar">
                <div class="flex lg:gap-[24px] gap-[10px] border-b-[1px] lg:px-[60px] font-medium text-grey-100 text-body-2">
                <!-- "All" Category -->
                    <router-link :to="`?category=`"
                        :class="{ 'border-b-[2px] border-primary-300 text-primary-300': !selectedCategory }"
                        class="p-[16px] cursor-pointer"
                    > All </router-link>

                    <router-link :to="`?category=${category?.category}`"
                        v-for="(category, index) in categories"
                        :key="index"
                        :class="{ 'border-b-[2px] border-primary-300 text-primary-300': selectedCategory === category?.category }"
                        class="p-[16px] cursor-pointer"
                    > {{ category?.category }} </router-link>
                </div>
            </div>

            <div class="bg-[#f0f0f0]" >
                <div class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] px-[10px] py-[32px]" v-if="loading">
                    <div v-for="index in 10" :key="index" class="lg:p-[12px] p-[6px] rounded-[8px] cursor-default">
                        <div class="bg-gray-300 h-[200px] rounded-[8px] mb-[12px] animate-pulse"></div>
                        <div class="bg-gray-300 h-[20px] rounded-[4px] mb-[6px] animate-pulse"></div>
                        <div class="bg-gray-300 h-[16px] rounded-[4px] animate-pulse"></div>
                    </div>
                </div>
                <div class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] px-[10px] py-[32px]" v-if="!loading">
                    <div v-for="(menu, index) in storeMenu" :key="index" class="lg:p-[12px] p-[6px] rounded-[8px] cursor-pointer" @click="openModal(menu)">
                        <div :style="getBackgroundStyle(menu.imageUrl, 200)"></div>
                        <div class="font-semibold mt-[12px]">{{ menu.name }}</div>
                        <div class="my-[12px] text-caption text-grey-200">{{ menu.price }}</div>
                    </div>
                </div>
            </div>

            <!-- Login Modal -->
            <div v-show="isLoginModalOpen" class="modal-overlay">
                <div class="modal-conten w-[550px] h-full overflow-auto relative no-scrollbar">
                    <div class="bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <div class="flex justify-between items-center">
                            <div class="text-grey-300 text-sub-heading">Login</div>
                            <Icon icon="ant-design:close-outlined" @click="closeLoginModal" class="cursor-pointer" />
                        </div>
                        <form @submit.prevent="handleLoginSubmit($event)">
                            <div class="grid gap-[8px] mt-[16px]">
                                <InputField 
                                    type="email" 
                                    label="email" 
                                    name="email"
                                    :value="loginFormHandler.values.email"
                                    :errors="loginFormHandler.errors.email"
                                    @update:blur:value="loginFormHandler.handleBlur"
                                    @update:value="loginFormHandler.handleChange"
                                />
                                <InputField 
                                    type="password" 
                                    label="Password"
                                    name="password"
                                    :value="loginFormHandler.values.password"
                                    :errors="loginFormHandler.errors.password"
                                    @update:blur:value="loginFormHandler.handleBlur"
                                    @update:value="loginFormHandler.handleChange" 
                                />
                            </div>
                            <div class="text-right text-caption text-grey-200 underline mb-[16px] mt-[8px]">Forget Password</div>

                            <ActionButton theme="primary" label="Login" class="whitespace-nowrap mb-[16px]" :isSubmit="true" :disable="loginLoading" />

                        </form>

                        <div class="flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]">
                            <div class="text-caption text-grey-200 underline">Don't have an account?</div>
                            <div class=" text-caption text-grey-200 underline">
                                <ActionButton theme="outlined" label="Signup" class="whitespace-nowrap" :handle-click="() => openSignUpModal()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sign Up Modal -->
            <div v-show="isSignUpModalOpen" class="modal-overlay">
                <div class="modal-conten w-[550px] h-full overflow-auto relative no-scrollbar">
                    <div class="bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <div class="flex justify-between items-center">
                            <div class="text-grey-300 text-sub-heading">Sign Up</div>
                            <Icon icon="ant-design:close-outlined" @click="closeSignUpModal" class="cursor-pointer" />
                        </div>
                        <form @submit.prevent="handleSignupSubmit($event)">
                            <div class="grid gap-[8px] my-[16px]">
                                <div class="grid grid-cols-2 items-center gap-[16px]">
                                    <InputField
                                        type="text"
                                        label="First Name"
                                        name="firstName"
                                        :value="signupFormHandler.values.firstName"
                                        :errors="signupFormHandler.errors.firstName"
                                        @update:blur:value="signupFormHandler.handleBlur"
                                        @update:value="signupFormHandler.handleChange"
                                    />
                                    <InputField
                                        type="text"
                                        label="Last Name"
                                        name="lastName"
                                        :value="signupFormHandler.values.lastName"
                                        :errors="signupFormHandler.errors.lastName"
                                        @update:blur:value="signupFormHandler.handleBlur"
                                        @update:value="signupFormHandler.handleChange"
                                    />
                                </div>
                                <InputField
                                    type="text"
                                    label="Email"
                                    name="email"
                                    :value="signupFormHandler.values.email"
                                    :errors="signupFormHandler.errors.email"
                                    @update:blur:value="signupFormHandler.handleBlur"
                                    @update:value="signupFormHandler.handleChange"
                                />
                                <InputField
                                    type="text"
                                    label="Phone Number"
                                    name="phone"
                                    :value="signupFormHandler.values.phone"
                                    :errors="signupFormHandler.errors.phone"
                                    @update:blur:value="signupFormHandler.handleBlur"
                                    @update:value="signupFormHandler.handleChange"
                                />
                                <InputField 
                                    type="password" 
                                    label="Password" 
                                    name="password"
                                    :value="signupFormHandler.values.password"
                                    :errors="signupFormHandler.errors.password"
                                    @update:blur:value="signupFormHandler.handleBlur"
                                    @update:value="signupFormHandler.handleChange"
                                />
                            </div>
                            <ActionButton theme="primary" label="Sign Up" class="whitespace-nowrap mb-[16px]" :isSubmit="true" :disable="signupLoading" />
                        </form>


                        <div class="flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]">
                            <div class="text-caption text-grey-200 underline">Already have an account?</div>
                            <div class=" text-caption text-grey-200 underline">
                                <ActionButton theme="outlined" label="Login" class="whitespace-nowrap" :handle-click="() => openLoginModal()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AppModal :openModal="isModalOpen" @close="closeModal">
                <div class="relative">
                    <div :style="getBackgroundStyle(selectedMenuDetails?.imageUrl, 300)"></div>
                    <div @click="closeModal" class="absolute right-[20px] top-[20px] bg-black opacity-70 w-[32px] h-[32px] rounded-[8px] border-[1px] border-white cursor-pointer">
                        <Icon icon="ant-design:close-outlined" width="18" height="18" class="text-white absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
                </div>

                <div class="flex items-center justify-between lg:px-[32px] px-[10px] pt-[24px]">
                    <div class="font-semibold text-heading-4">{{ selectedMenuDetails?.name }}</div>
                    <div class="text-body-2 font-medium text-grey-300">{{ formatPrice(selectedMenuDetails?.price) }}</div>
                </div>

                <div class="text-grey-200 font-medium border-b-[1px] border-solid border-grey-50 py-[24px] lg:px-[32px] px-[10px]">
                    {{ selectedMenuDetails?.description }}
                </div>

                <div class="flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]">
                    <div class="font-semibold text-body-2">Quantity: </div>
                    <div class="bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]">
                        <div class="bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer" @click="decrementQuantity(selectedMenuDetails)">
                            <Icon icon="ant-design:minus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                        </div>
                        <div class="">{{ selectedMenuDetails?.quantity }}</div>
                        <div class="bg-white rounded-full w-[32px] h-[32px] relative cursor-pointer" @click="incrementQuantity(selectedMenuDetails)">
                            <Icon icon="ant-design:plus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                        </div>
                    </div>
                </div>

                <TextArea label="Add a note" placeholder="Add a note to your order (optional)" class="lg:px-[32px] px-[10px]" />

                <div class="flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]">
                    <div class="font-semibold text-body-2">Total Price: </div>
                    <div class="text-body-2 font-medium">{{ formatPrice(selectedMenuDetails?.totalPrice) }}</div>
                </div>

                <div class="flex items-center gap-[16px] border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] py-[24px] mt-[24px]">
                    <ActionButton theme="primary" :label="`Add to Cart ${ formatPrice(selectedMenuDetails?.totalPrice) }`" class="whitespace-nowrap" @click="addToCart" />
                    <ActionButton :label="`Buy Now ${ formatPrice(selectedMenuDetails?.totalPrice) }`" class="whitespace-nowrap bg-grey-50 text-grey-300" @click="handleBuyNow" />
                </div>
            </AppModal>

            <!-- Orders Modal -->
            <AppModal header-content="Orders" :openModal="isOrdersModalOpen" @close="closeOrdersModal">
                <div class="mt-[100px] flex items-center mx-[20px] text-body-2 gap-[32px] border-b-[1px] border-grey-50 text-grey-200">
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Ongoing'}" class='py-[12px] cursor-pointer' @click="changeTab('Ongoing')">Ongoing</div>
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Delivered'}" class='cursor-pointer' @click="changeTab('Delivered')">Delivered</div>
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Cancelled'}" class='cursor-pointer' @click="changeTab('Cancelled')">Cancelled/Returned</div>
                </div>

                <div v-for="(order, index) in orderStatuses[currentTab as keyof typeof orderStatuses]" :key="index" class="rounded-[8px] cursor-pointer grid gap-[8px] border-[1px] border-solid border-grey-50 m-[20px]">
                    <div class="flex gap-[8px] p-[10px]">
                        <div :style="getCartBackgroundStyle(order.imageUrl, 84, 84)"></div>
                        <div class="">
                            <div class="font-semibold">{{ order.name }}</div>
                            <div class="text-caption text-grey-200">{{ order.ingredient }}</div>
                            <div :class="getStatusColorClass(order.status)" class="px-[8px] py-[4px] rounded-[4px] w-fit text-caption mt-[14px]">{{ order.status }}</div>
                        </div>
                    </div>

                    <div class="bg-grey-50 py-[4px] px-[8px] flex items-center justify-between text-caption rounded-b-[8px]">
                        <div>{{ order.deliveryDate }}</div>
                        <div v-if="currentTab != 'Cancelled' && 'receipt' in order" class="text-grey-200">
                            <router-link to="/receipt">{{ order.receipt }}</router-link>
                        </div>
                    </div>
                </div>
            </AppModal>
        </main>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import SideModel from '@/components/shared/modal/SideModel.vue';
import StarRating from '@/components/shared/ratings/StarRating.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { Icon } from '@iconify/vue';
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { getRestaurantMenu, useSignup, useLogin, getAuthInformation, useLogout } from './restaurant.helper';
import { formatPrice } from '@/utility/helper/utils'
import cartService from '@/utility/services/microsite/cart.service';
import { useRoute, useRouter } from 'vue-router';
import CheckoutService from '@/utility/services/microsite/checkout.service';
import { useStore } from 'vuex';

const { token, userData } = getAuthInformation()
const signupData = ref();
const loginData = ref();
const signupLoading = ref(false);
const loginLoading = ref(false);
const storeX = useStore()

const isDropdownOpen = ref(false);
const isLoginModalOpen = ref(false);
const isSignUpModalOpen = ref(false);

const openLoginModal = () => {
    closeDropdown();
    isLoginModalOpen.value = true;
    isMobileMenuOpen.value = false;
    isSignUpModalOpen.value = false;
};

const openSignUpModal = () => {
    closeDropdown();
    isSignUpModalOpen.value = true;
    isLoginModalOpen.value = false;
    isMobileMenuOpen.value = false;
};

const closeDropdown = () => {
    isDropdownOpen.value = false;
};

const router = useRouter();
const route = useRoute();

const goToContactUS = () => {
    router.push('/').then(() => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    });
};

const checkout = async (user?: any) => {
    const { storeId, restaurantId } = route?.params; 

    try {
        if (user?.id || userData?.id) {
            const checkoutResponse = await CheckoutService.checkout(`${storeId}`, { orders: [{orderDetails: [...cart.value]}] });
        
            if (checkoutResponse && cart.value.length > 0) {
                router.push({ 
                    path: `/checkout/${checkoutResponse.orderId}`, 
                    query: { storeId, restaurantId } 
                });
            }
        } else {
            openSignUpModal()
        }
        
    } catch (error) {
        console.error("Checkout error:", error);
    }
}

const { signupFormHandler, signupResponseData } = useSignup(storeX, openLoginModal);
const { loginFormHandler } = useLogin(storeX, {callback: {checkout: checkout}})


const handleSignupSubmit = async (event: Event) => {
    event.preventDefault();
    signupLoading.value = true

    try {
        await signupFormHandler.handleSubmit(event);
        signupData.value = signupFormHandler.values;

        console.log({signupResponseData});

        signupLoading.value = false
    } catch (error) {
        console.log(error)
        return;
    }
};


const handleLoginSubmit = async (event: Event) => {
    event.preventDefault();
    loginLoading.value = true;

    try { 
        await loginFormHandler.handleSubmit(event);
        loginData.value = loginFormHandler.values;
        loginLoading.value = false;
    } catch (error) {
        console.log(error);
        return loginLoading.value = false; 
    }
};


const { storeMenu, loading, store, categories, selectedCategory } = getRestaurantMenu();
console.log({categories})

const isModalOpen = ref(false);
const selectedMenuDetails = ref();


const isSideModalOpen = ref(false);

const openSideModal = () => {
    isSideModalOpen.value = true; 
};

const closeSideModal = () => {
    isSideModalOpen.value = false; 
};

const cleanPrice = (price: string | number | undefined | null): number => {
    if (price === undefined || price === null) {
        console.error("Price is undefined or null. Defaulting to 0.");
        return 0;
    }

    const priceString = typeof price === 'number' ? price.toString() : price;

    const cleanedPrice = priceString.replace(/[^0-9.-]+/g, "");

    return parseFloat(cleanedPrice);
};

const openModal = (menus: any) => {
    console.log("Menu Object:", menus);
    selectedMenuDetails.value = {
        ...menus,
        quantity: 1, 
        price: menus.price ? cleanPrice(menus.price) : 0, 
        totalPrice: menus.price ? cleanPrice(menus.price) : 0, 
    };
    console.log("Selected Menu Details:", selectedMenuDetails.value);
    isModalOpen.value = true;
};

const imageStyle = ref({
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const getBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const getCartBackgroundStyle = (imageUrl: string, height?: number, width?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: `${width}px` || '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

watch(store, (newStore) => {
    console.log({newStore});
    
    if (newStore) {
        imageStyle.value.backgroundImage = `url(${newStore.cover})`;
    }
}, { immediate: true });

const isFixed = ref(false);
const navBar = ref<HTMLElement | null>(null);
let navBarOffsetTop = 0;

const handleScroll = () => {
    if (navBar.value) {
        const currentScroll = window.scrollY;
        isFixed.value = currentScroll >= navBarOffsetTop;
    }
};

onMounted(() => {
    if (navBar.value) {
        navBarOffsetTop = navBar.value.offsetTop; 
    }
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});


const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedMenuDetails.value = null; 
};


const isOrdersModalOpen = ref(false);
const currentTab = ref('Ongoing'); 

const orderStatuses = {
    Ongoing: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
    ],
    Delivered: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Delivered',
            deliveryDate: 'Delivered on: 04 July, 2024',
            receipt: 'Download receipt'
        }
    ],
    Cancelled: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled - Payment Unsuccessful',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        }
    ]
};

const changeTab = (tab: string) => {
    currentTab.value = tab;
};

const openOrdersModal = () => {
    isOrdersModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeOrdersModal = () => {
    isOrdersModalOpen.value = false;
};

const logout = async () => {
    await useLogout(() => {
        window.location.reload();
    });
};

const dropdownItems = [
    { icon: 'ant-design:shopping-outlined', text: 'Orders', /* action: openOrdersModal, */ disabled: true },
    { icon: 'ant-design:bell-outlined', text: 'Notifications', disabled: true },
    { icon: 'ant-design:history-outlined', text: 'Recently Viewed', disabled: true },
    { icon: 'ant-design:gift-outlined', text: 'Rewards', disabled: true },
    { icon: 'ant-design:heart-outlined', text: 'Favorite Items', disabled: true },
    { icon: 'ant-design:logout-outlined', text: 'Logout', class: 'border-t-[1px] border-grey-50 text-error pt-[11px] -mb-[11px]', action: logout, disabled: false }
];

const getStatusColorClass = (status: any) => {
    switch (status) {
        case 'Shipped':
            return 'bg-yellow-500'; 
        case 'Waiting to be shipped':
            return 'bg-link text-white'; 
        case 'Delivered':
            return 'bg-success text-white'; 
        case 'Cancelled - Payment Unsuccessful':
            return 'bg-black text-white'; 
        case 'Cancelled':
            return 'bg-black text-white'; 
        default:
            return '';
    }
};

const cartItemsCount = ref(0);

interface CartItem extends Record<string, string | number> {
    imageUrl: string;
    name: string;
    price: string;
    quantity: number;
    totalPrice: number;
}

const cart = ref<CartItem[]>([]);

const totalPrice = computed(() => {
    return cart.value.reduce((acc, item) => acc + item.totalPrice, 0);
});

onMounted(async () => {
    try {
       if (userData?.id) {
            const userId = userData?.id;
            const cartData = await cartService.getCart(userId);
            cart.value = cartData.cart.items || [];
            cartItemsCount.value = cart.value.length;
        } else if (localStorage.getItem('cartItems')) {
            const storageCartItems = ref();
            storageCartItems.value = JSON.parse(`${localStorage.getItem('cartItems')}`)
            cart.value = storageCartItems.value || [];
            cartItemsCount.value = cart.value.length;
        }
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
});

const removeFromCart = async (menuId: string | number) => {
    try {
        const userId = userData?.id;
        console.log({ menuId });

        if (userId) {
            await cartService.removeFromCart(userId, menuId);

            const itemIndex = cart.value.findIndex(item => item.menuId === menuId);
            if (itemIndex > -1) {
                cart.value.splice(itemIndex, 1);
                cartItemsCount.value = cart.value.length;
            }
        } else {
            const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');

            if (existingCart.length < 1) {
                localStorage.removeItem("cartItems")
            }
            const updatedCart = existingCart.filter((item: any) => item.menuId !== menuId);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            cart.value = updatedCart; 
            cartItemsCount.value = cart.value.length;

            
        }
    } catch (error) {
        console.error("Error removing item from cart:", error);
    }
};

const incrementQuantity = async (item: CartItem) => {
    const price = cleanPrice(item.price);
    if (isNaN(price)) {
        console.error("Invalid price value:", item.price);
        return;
    }

    item.quantity += 1;
    item.totalPrice = price * item.quantity;

    try {
        const userId = userData?.id;
        const cartItem = {
            menuId: item.menuId,
            price: item.price,
            quantity: 1, 
            totalPrice: item.totalPrice,
            imageUrl: item.imageUrl,
            name: item.name,
            ingredient: item.ingredient,
        };

        const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
        const existingItem = existingCart.find((cart: CartItem) => cart.menuId === item.menuId);

        if (existingItem) {
            existingItem.quantity += 1; 
            existingItem.totalPrice = cleanPrice(existingItem.price) * existingItem.quantity;
            localStorage.setItem('cartItems', JSON.stringify(existingCart)); 
        }

        await cartService.addToCart(userId, [cartItem]); 
    } catch (error) {
        console.error("Error adding to cart:", error);
    }
};

const decrementQuantity = async (item: CartItem) => {
    if (item.quantity > 1) {
        const price = cleanPrice(item.price);
        if (isNaN(price)) {
            console.error("Invalid price value:", item.price);
            return;
        }

        item.quantity -= 1;
        item.totalPrice = price * item.quantity;

        try {
            const userId = userData?.id;
            const cartItem = {
                menuId: item.menuId,
                price: item.price,
                quantity: -1, 
                totalPrice: item.totalPrice,
                imageUrl: item.imageUrl,
                name: item.name,
                ingredient: item.ingredient,
            };

            const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
            const existingItem = existingCart.find((cart: CartItem) => cart.menuId === item.menuId);

            if (existingItem) {
                existingItem.quantity -= 1;
                existingItem.totalPrice = cleanPrice(existingItem.price) * existingItem.quantity;
                localStorage.setItem('cartItems', JSON.stringify(existingCart)); // Update local storage
            }

            await cartService.addToCart(userId, [cartItem]);
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }
};

const addToCart = async () => {
    if (selectedMenuDetails.value) {
        const existingItem = cart.value.find(item => item.menuId === selectedMenuDetails.value.id);
        if (existingItem) {
            // console.warn("Item is already in the cart.");
            storeX.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Ops!', x_message: "Item is already in the cart", duration: 5000 });
            return;
        }

        const userId = userData?.id;
        const cartItem: any = {
            menuId: selectedMenuDetails.value.id,
            price: cleanPrice(selectedMenuDetails.value.price),
            quantity: selectedMenuDetails.value.quantity,
            totalPrice: selectedMenuDetails.value.totalPrice,
            imageUrl: selectedMenuDetails.value.imageUrl,
            name: selectedMenuDetails.value.name,
            ingredient: selectedMenuDetails.value.ingredient,
        };

        try {
            if (userData?.id) {
                await cartService.addToCart(userId, [cartItem]);
                cart.value.push(cartItem);
                cartItemsCount.value = cart.value.length;
                closeModal();
            } else {
                const existingCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
                existingCart.push(cartItem);
                localStorage.setItem('cartItems', JSON.stringify(existingCart));
                cart.value.push(cartItem); // Update the local cart state
                cartItemsCount.value = cart.value.length;
                closeModal();
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }
};

const handleBuyNow = async() => {
    await addToCart().then(() => {
        checkout()
    })
}

const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const closeLoginModal = () => {
    isLoginModalOpen.value = false;
    isDropdownOpen.value = false;
};

const closeSignUpModal = () => {
    isSignUpModalOpen.value = false;
    isDropdownOpen.value = false;
};

</script>

<style scoped>
.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; 
    background-color: white;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.side-modal {
    position: fixed;
    top: 0;
    right: 0; /* Aligns the modal to the right side */
    width: 400px; /* Adjust width as needed */
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%); /* Initially hidden */
    display: flex;
    flex-direction: column;
}

.side-modal.open {
    transform: translateX(0); /* Slide in when open */
}

.overflow-y-auto {
    overflow-y: auto; /* Allow scrolling */
    flex: 1; /* Take up remaining space */
}
</style>