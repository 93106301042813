import ApiService from "../api.service";
import { endpoint } from "@/utility/endpoint";

export default class CheckoutService {

    static async checkout(storeId: string, data: any): Promise<any> {
        const url = endpoint.checkout.replace(':storeId', storeId);
        const response = await ApiService.post(url, data);
        return response.data.data;
    }

}