import { ref } from "vue";

export const blogs = ref([
    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'Restaurant on steroids',
        location: "I am a restaurant. And I am going to tell you what I'm about to tell you because somehow you're connected to food. For me, being connected to food isn't just enough yet, caring about good food and anything that makes it good is the real deal! Okay.",
        content: (`I am a restaurant. And I am going to tell you what I'm about to tell you because somehow you're connected to food. For me, being connected to food isn't just enough yet, caring about good food and anything that makes it good is the real deal! Okay.

I made a discovery. This discovery has switched on my steroids mode and I'm no regular restaurant anymore. Enough of the suspense… Have you ever imagined an ecosystem for restaurants? A restaurant's tightly interconnected heaven! Let me show you one.

Now picture yourself, a restaurant owner, being augmented by tech all through your restaurant journey. Yes-all through. I mean, right from the moment you start considering entering the business all through till you're established.

Being new in the food business, you are really confused with where to start and how not to get short-changed. You thought it was just to open up a building and start selling food but reality seems to be proving you wrong. At the end of the day, it's a business to - has risks and you can't be too certain of your predictions. Just when you were giving up, you discovered my discovery and activated your steroids levels. You now have professionals who know the ins-and-outs of the food world and hold your hand all through the journey. Their experience becomes your tool and there's almost no threat that your business could fail. In a few weeks, you are up and running, like a professional, well grounded in the art of touching hearts with healthy meals.

…

Few months down the line, your restaurant is growing and it seems you really can't keep up with the speed of this growth. Some foods sell better than others. Some customers frequent more than others. Some days have more sales than others. There's a trend but you don't see it yet. You now have numerous customers frequenting your lovely food place. You should be happy. But you're frustrated because you're always short-staffed. No matter how many more employees you get, it keeps seeming like you need more. And you don't think it's good business to employ yet more workers to the batch you just employed last week. You can sense you need more but it's definitely not workers. Amidst your frustrations, you remember your friends who got you started - your discovery. Alas, they had more for you to discover. This time, this new discovery is tailored towards solving your exact predicament. You discover the Registered System. That was the solution all along.

With the Registered System, a tech solution to common restaurant problems, you are able to understand market trends, your restaurant trends, what kind of food your customers like, your customers and more. Let me give you a little context here: I started running a promo last week. How much impact has it had on my business so far? Should I stop the promo or continue? Am I losing customers? What kind of food do my customers like best? What food on the menu do they seldom buy? Do I need to reduce the quantity of such food I prepare daily?Which customer has a routine of coming when? Have they stopped coming? Do I need to reach out to them? What's my total revenue so far? What do I spend on most? Can I see and access all that's going on in my restaurant even when I'm not there? Does that mean I can run multiple restaurants at once with little to worry about? Can I have exclusive access to some of my restaurant data?

With the registered system, you get all positive answers to your questions. Having questions like this answered significantly maximizes your profit and eliminates the losses. The Registered System, your newest discovery, gave you the answers. All the answers and more that you never thought to ask.

But the Registered System has yet more to offer - a payment system! A freaking bad-ass payment system! Your customers do not have to come in with cash. They can make seamless and fast payment for their orders with their cards. It gets even easier. The receipts for every transaction are generated and signed by the System itself. You can't ever be scammed.

There's a lot more to discover about how much more solutions FoodCart has to offer your business as a restaurant owner but trust me, I'll make a sequel to this and show you more. And oh! Let me give you a spoiler alert on what my discovery is. It's FoodCart. My restaurant start-up consultancy friends, the amazing Registered System and many more I'll harness in coming sequels, it's all FoodCart saving the business in varying morphs.

I discovered FoodCart and I became a restaurant on steroids.`)
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'Restaurant on steroids (II)',
        location: "I am a restaurant. Remember me? I was telling you about my discovery the last time but I kept the best for the last. Now that your restaurant has fearlessly braved through the stormy beginnings and you feel the burning urge to get even better. ",
        content: `I am a restaurant. Remember me? I was telling you about my discovery the last time but I kept the best for the last. Now that your restaurant has fearlessly braved through the stormy beginnings and you feel the burning urge to get even better. Of course, a restaurant that stops improving becomes a dinosaur! You know this well but you seem to have had all your needs catered for. You ask and re-ask yourself in wobbly doubts: "Can this get any better?". Little do you know that your friends, your discovery have the answer you seek. "Yes, there's always room, with FoodCart, to get legendarily good!", they tell you reassuringly. You were awestruck by the payment system already and waited readily to discover what more improvement there could be.

Before I bombard you with what FoodCart has in the pipeline still journeying their way to you, let's take a moment to talk about the FoodCart microsite which is already here and how it's the next level you seek for your business


You know how you have always wanted a way to access customers that are not physically present with you and how you wish they could access your menu in an interactive manner. You know how you wish that these stranger customers, who form the large percentage of your potential customer base could become actual customers and you thereby become limitless in your distribution? You know how you wish that these potential customers could buy your stuff online, you receive their orders and make the delivery? You know how the "but" that restricts the actualization of your wishes is having to pay through the nose for a software/tech team to build your e-commerce? That's the context of the Microsite. With the microsite, you have your restaurant practically online and easily accessed just with the click of your dedicated link. Your customers make their orders seamlessly and you handle the delivery.

You feel so wowed. This was it! You were at the next level that you sought. You tried it out and your sales doubled in the next couple of weeks. Several weeks into this and you're now used to multiple folds of sales than you used to have when you thought that was the best it could ever get. FoodCart is happy that you're happy. That's what they exist for, afterall to put a never-ending excitement on your face.

Just when we all agree that this is the height of it. FoodCart has been amazing! As you bask in the glory of your most recent wins, FoodCart goes back to the drawing board to chart out ways of improving your experience yet again, driving home the fact that it's indeed the restaurants' heaven.

Your discovery has now come up with new solutions and they're actively working to bring it to you without delay. You can't wait for it! Why? Because now, you can buy your food materials from a centralized Marketplace at actual wholesales prices and get them delivered to you. You can have your daily food materials on-demand without hassle just by browsing through the Marketplace and making your orders. What more. You really can't wait because… (drumroll…)… FoodCart would be handling your deliveries! All the bottlenecks and hassles involved in having your customers receive their orders would be lifted off you. And now, even if you wanted to be a ghost restaurant 🥲, you can be the ideal ghost restaurant attaining your full ghost potentials.`
    },

    {
        imageUrl: require('@/assets/images/images/strategy.jpeg'),
        name: 'Customer Retention Strategies That Work',
        location: "Attracting new customers is great, but keeping them coming back is where real growth happens. Studies show that increasing customer retention by just 5% can boost profits by 🤯",
        content: `Why Customer Retention Matters More Than Ever
Attracting new customers is great, but keeping them coming back is where real growth happens. Studies show that increasing customer retention by just 5% can boost profits by 25% to 95%! 🤯
So, how can restaurant owners build lasting relationships with their customers? Let’s dive into proven, results-driven strategies to help your restaurant thrive!

1. Offer Personalized Dining Experiences
Customers crave personalized service—from tailored menu recommendations to remembering their favorite orders.
✔️ Use customer data & order history to offer personalized discounts or meal suggestions.
✔️ Send birthday specials, anniversary offers, or exclusive VIP deals.
✔️ Create a warm, welcoming atmosphere where guests feel valued.
🔹 Pro Tip: With FoodCart, you can track customer preferences and send personalized offers automatically!

2. Implement a Loyalty & Rewards Program
People love being rewarded! A well-structured loyalty program keeps customers engaged and increases repeat visits.
✔️ Offer points for every purchase that customers can redeem for discounts or free items.
✔️ Use a tiered system (e.g., Silver, Gold, Platinum members) to encourage more spending.
✔️ Keep it simple and accessible—digital loyalty programs work best!
🔹 Pro Tip: Digital rewards programs work 2X better than paper-based ones. Get started with FoodCart’s automated loyalty system!

3. Leverage Email & SMS Marketing
Staying top-of-mind is key to keeping customers engaged. Strategic email & SMS marketing is one of the most effective ways to boost retention.
✔️ Send exclusive offers, new menu updates, or limited-time deals.
✔️ Automate friendly follow-ups after a visit, thanking customers for dining with you.
✔️ Use data insights to segment customers & send targeted promotions.
🔹 Pro Tip: Use FoodCart’s marketing automation tools to send personalized messages at the perfect time!

4. Enhance Customer Service & Staff Training
Exceptional service = Loyal customers! A great meal can be ruined by poor service, so invest in consistent staff training to ensure top-notch hospitality.
✔️ Train staff to remember repeat customers & their preferences.
✔️ Implement a quick response system for customer complaints.
✔️ Encourage a friendly, welcoming atmosphere at all times.
🔹 Pro Tip: Restaurants with strong customer service retain 89% of their customers. Invest in staff training to stand out!

5. Use Smart Technology to Optimize the Customer Experience
In today’s digital world, convenience is everything. A tech-driven approach enhances efficiency and keeps customers satisfied.
✔️ Offer online ordering & reservations to reduce wait times.
✔️ Use POS systems that track customer preferences & spending habits.
✔️ Implement mobile payment & contactless options for a smoother experience.
🔹 Pro Tip: FoodCart makes restaurant management seamless—helping you track customers, send promotions, and manage reservations in one place!

6. Encourage Customer Feedback & Act on It
Customers want to be heard. Encouraging feedback shows that you care and value their opinion.
✔️ Make it easy for customers to leave reviews (Google, Yelp, social media).
✔️ Act on negative feedback quickly and turn unhappy customers into loyal ones.
✔️ Showcase positive reviews on your website and social media for credibility.
🔹 Pro Tip: 85% of customers trust online reviews as much as personal recommendations. Don’t underestimate the power of a great testimonial!

Final Thoughts: Keep Them Coming Back!
Customer retention isn’t about getting someone to visit once—it’s about making them a fan for life. By leveraging technology, personalized service, and smart marketing, restaurant owners can build lasting relationships that drive revenue and brand loyalty.
🚀 Ready to take your restaurant to the next level? Let FoodCart help you automate, engage, and retain customers effortlessly.
👉 Join the future of restaurant management today at www.foodcart.ng
        `
    },
    {
        imageUrl: require('@/assets/images/images/record-keeping.png'),
        name: 'How Manual Record-Keeping is Damaging Your Restaurant More Than You Think',
        location: "Running a restaurant isn’t just about serving great food—it’s about keeping things organized, efficient, and profitable. But if ...",
        content: (`Running a restaurant isn’t just about serving great food—it’s about keeping things organized, efficient, and profitable. But if you’re still relying on pen and paper to track sales, manage inventory, and record expenses, you may be losing more than you realize.
At first glance, manual record-keeping seems simple and cost-effective. But here’s the real cost:

1. Inaccurate Records = Lost Profits

One small mistake in your calculations, one misplaced order slip, or one forgotten entry at the end of a busy day can mean hundreds—if not thousands—of naira lost over time.
Without a real-time tracking system, how do you know:
❌ If your sales match your stock levels?
❌ If all customer payments were recorded correctly?
❌ If your business is actually making a profit?
In a fast-paced restaurant, errors add up quickly, and manual record-keeping makes them almost unavoidable.

2. Paper Records Get Lost, Stolen, or Damaged

Picture this: It’s the end of a long day, and you’re ready to tally up the numbers—only to find that some receipts are missing. Or worse, a spilled drink ruins your sales records. 😨
With manual records, you’re always one accident away from losing critical business data. And let’s be real—when paperwork piles up, things get misplaced, forgotten, or even stolen.

3. Wasted Time = Wasted Money

How much time do you or your staff spend every day sorting through receipts, writing down transactions, or fixing errors? ⏳
Now imagine having all that information automatically recorded, neatly organized, and accessible in real time—without lifting a pen.
Time saved = More focus on growing your restaurant.

4. No Insights = No Growth

If you’re not tracking data in real time, you’re making business decisions blindly.
With manual records, you don’t get instant answers to important questions like:
📊 What’s your best-selling dish?
📉 Which days bring in the most revenue?
💰 Where are you losing money?
Without these insights, it’s nearly impossible to optimize your menu, pricing, or overall strategy to scale your business.
Time to Ditch the Paper & Go Digital! 🚀
FoodCart eliminates the headaches of manual record-keeping by bringing everything into one smart, automated system.
✅ Track every sale in real time – No more lost revenue.
✅ Monitor inventory automatically – Reduce waste & theft.
✅ Access business insights instantly – Make better decisions, faster.
Your restaurant deserves better. Let’s simplify, track, and grow—together.`)
    },
]);