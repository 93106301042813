import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "grid lg:px-[60px] items-center px-[20px] py-[12px] border-b-[1px]",
  style: {"grid-template-columns":"1fr auto"}
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex items-center gap-[6px] border-[1px] rounded-full w-fit lg:px-[20px] p-[8px] lg:py-[3px]" }
const _hoisted_4 = {
  class: "lg:grid grid-cols-2 items-center gap-[10px] hidden",
  style: {"grid-template-columns":"auto 2fr"}
}
const _hoisted_5 = { class: "flex items-center gap-[16px] bg-grey-50 rounded-[8px] p-[2px] text-body-2" }
const _hoisted_6 = { class: "lg:px-[60px] px-[10px]" }
const _hoisted_7 = {
  key: 0,
  class: "grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-[16px] overflow-auto no-scrollbar"
}
const _hoisted_8 = {
  key: 1,
  class: "grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-[16px] overflow-auto no-scrollbar"
}
const _hoisted_9 = { class: "font-semibold mt-[12px]" }
const _hoisted_10 = { class: "my-[12px] text-caption text-grey-200" }
const _hoisted_11 = { class: "mt-[60px] border-t-[1px]" }
const _hoisted_12 = { class: "grid bg-[#fff] lg:grid-cols-2 lg:px-[60px] px-[20px] py-[60px]" }
const _hoisted_13 = { class: "lg:flex lg:gap-[120px] grid lg:grid-cols-2 gap-[40px] lg:ml-auto ml-0 lg:mt-0 mt-[50px]" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "text-caption mt-[24px] grid gap-[14px]" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "text-caption mt-[24px] grid gap-[14px]" }
const _hoisted_18 = { class: "bg-[#fff] lg:px-[60px] py-[34px] lg:flex items-center lg:justify-between text-caption" }
const _hoisted_19 = { class: "lg:flex text-center gap-[24px]" }
const _hoisted_20 = { class: "text-white flex gap-[16px] lg:mt-0 mt-[20px] w-fit lg:ml-auto mx-auto" }
const _hoisted_21 = {
  href: "https://x.com/foodcartng",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_22 = {
  href: "https://www.facebook.com/foodcart.tech",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_23 = {
  href: "https://www.youtube.com/channel/UCegkuRFbZMXD2rOJhtFg2Kg",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_24 = {
  href: "https://www.instagram.com/foodcart.ng/",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue'
import { getRestaurants, items } from './restaurant.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'RestaurantsView',
  setup(__props) {

const { stores, loading } = getRestaurants();

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px',
    width: '100%',
    borderRadius: '8px'
});

const activeIndex = ref(0);

const setActive = (index: any) => {
    activeIndex.value = index;
};

const activeOption = ref('Delivery');


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "48",
                height: "48"
              }, null, 8, ["imageUrl"]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-itim text-heading-5 lg:block hidden" }, "Foodcart", -1))
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["px-[20px] py-[8px] cursor-pointer", {'bg-white rounded-[8px]': activeOption.value === 'Delivery'}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (activeOption.value = 'Delivery'))
          }, " Delivery ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["px-[20px] py-[8px] cursor-pointer", {'bg-white rounded-[8px]': activeOption.value === 'Pickup'}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (activeOption.value = 'Pickup'))
          }, " Pickup ", 2)
        ])
      ])
    ]),
    _createElementVNode("main", null, [
      _createElementVNode("section", _hoisted_6, [
        _cache[5] || (_cache[5] = _createElementVNode("header", { class: "my-[37px] items-center" }, [
          _createElementVNode("div", { class: "lg:text-[28px] text-heading-4 font-semibold font-sourceSerifPro" }, "All restaurants near you")
        ], -1)),
        _createElementVNode("main", null, [
          (_unref(loading))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                  return _createElementVNode("div", {
                    key: index,
                    class: "border border-gray-200 rounded-lg p-4 animate-pulse"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("div", { class: "bg-gray-100 h-40 rounded-lg" }, null, -1),
                    _createElementVNode("div", { class: "mt-4" }, [
                      _createElementVNode("div", { class: "bg-gray-100 h-6 rounded w-3/4" }),
                      _createElementVNode("div", { class: "bg-gray-100 h-4 rounded mt-2 w-5/6" })
                    ], -1)
                  ]))
                }), 64))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stores), (store, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "border-[1px] border-grey-50 lg:p-[12px] p-[8px] rounded-[8px]"
                  }, [
                    _createVNode(_component_router_link, {
                      to: `/restaurants/${store.restaurantId}/store/${store.id}`
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle(getBackgroundStyle('https://png.pngtree.com/png-clipart/20220903/ourlarge/pngtree-chef-restaurant-logo-png-image_6136204.png'))
                        }, null, 4),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(store.name), 1),
                        _createElementVNode("div", _hoisted_10, [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-link text-medium" }, "Location:", -1)),
                          _createTextVNode(" " + _toDisplayString(`${store.address?.street} ${store.address?.lga} ${store.address?.state} ${store.address?.country}`), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ]))
        ])
      ])
    ]),
    _createElementVNode("footer", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "flex items-center gap-[6px] bg-white w-fit py-[8px] rounded-[50px] h-fit"
        }, {
          default: _withCtx(() => [
            _createVNode(ImageCard, {
              imageUrl: require('@/assets/images/logos/foodcart.png'),
              width: "48",
              height: "48"
            }, null, 8, ["imageUrl"]),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-itim lg:text-heading-5" }, "Foodcart", -1))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-heading-6 font-medium" }, "COMPANY", -1)),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_router_link, {
                to: "/",
                class: ""
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "about-us",
                class: ""
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("How it works")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "blog",
                class: ""
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Blog")
                ])),
                _: 1
              })
            ])
          ]),
          _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"\"><div class=\"text-heading-6 font-medium\">GET HELP</div><div class=\"text-caption mt-[24px] grid gap-[14px]\"><div class=\"\">Contact Us</div><div><a href=\"tel:+2349166190024\" class=\"\">+234 916 619 0024</a></div><div><a href=\"mailto:Sales@foodcart.ng\" class=\"\">Sales@foodcart.ng</a></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_16, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-heading-6 font-medium" }, "LEGAL", -1)),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_router_link, {
                to: "/privacy-policy",
                class: ""
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Privacy Policy")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/terms-of-services",
                class: ""
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("div", { class: "" }, "Terms of service", -1)
                ])),
                _: 1
              }),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "" }, "Cookie settings", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "" }, "© 2024 FoodCart. All rights reserved.", -1)),
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: ""
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("div", { class: "underline" }, "Privacy Policy", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/terms-of-services",
            class: ""
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createElementVNode("div", { class: "underline" }, "Terms of Service", -1)
            ])),
            _: 1
          }),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "underline" }, "Cookie settings", -1))
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("a", _hoisted_21, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:twitter-outlined",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ]),
          _createElementVNode("a", _hoisted_22, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:facebook-filled",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ]),
          _createElementVNode("a", _hoisted_23, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:youtube-outlined",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ]),
          _createElementVNode("a", _hoisted_24, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:instagram-outlined",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ])
        ])
      ])
    ])
  ], 64))
}
}

})