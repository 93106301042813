<template>
    <header class="grid lg:px-[60px] items-center px-[20px] py-[12px] border-b-[1px]" style="grid-template-columns: 1fr auto;">
        <router-link to="/">
            <div class="">
                <div class="flex items-center gap-[6px] border-[1px] rounded-full w-fit lg:px-[20px] p-[8px] lg:py-[3px]">
                    <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
                    <div class="font-itim text-heading-5 lg:block hidden">Foodcart</div>
                </div>
            </div>
        </router-link>
        
        <!-- <div class="lg:hidden">
            <ActionButton theme="outlined" prefixIcon="ant-design:menu-outlined" label="" class="w-fit whitespace-nowrap" />
        </div> -->
        
        <div class="lg:grid grid-cols-2 items-center gap-[10px] hidden" style="grid-template-columns: auto 2fr">
            <div class="flex items-center gap-[16px] bg-grey-50 rounded-[8px] p-[2px] text-body-2">
                <div 
                    class="px-[20px] py-[8px] cursor-pointer"
                    :class="{'bg-white rounded-[8px]': activeOption === 'Delivery'}"
                    @click="activeOption = 'Delivery'"
                >
                    Delivery
                </div>
                <div 
                    class="px-[20px] py-[8px] cursor-pointer"
                    :class="{'bg-white rounded-[8px]': activeOption === 'Pickup'}"
                    @click="activeOption = 'Pickup'"
                >
                    Pickup
                </div>
            </div>

            <!-- <div class="">
                <InputField type="text" prefixIcon="ant-design:search-outlined" />
            </div> -->

            <!-- <div class="">
                <ActionButton theme="outlined" prefixIcon="material-symbols:location-on-outline" label="Fajuyi Way, Ikeja GRA..." class="w-fit whitespace-nowrap" />
            </div> -->
        </div>

    </header>

    <main>
        <!-- <div class="flex lg:gap-[52px] gap-[20px] mx-auto lg:w-fit cursor-pointer lg:px-[60px] px-[20px] lg:pt-[60px] pt-[20px] w-full overflow-auto no-scrollbar">
            <div v-for="(item, index) in items" :key="index" class="w-fit" @click="setActive(index)">
                <div :class="['w-[60px] h-[60px] p-[14px] rounded-full', { 'bg-[#D7804C33]': activeIndex === index }]">
                    <ImageCard :imageUrl="item.icon" width="32" height="32" class="w-fit mx-auto" />
                </div>
                <div class="text-center text-caption mt-[10px]">{{ item.label }}</div>
            </div> 
        </div> -->

        <!-- <section class="pl-[60px] pt-[60px]">
            <header class="flex justify-between my-[37px] items-center pr-[60px]">
                <div class="text-[28px] font-semibold font-sourceSerifPro">Popular restaurants near you</div>
                <div class="flex items-center gap-[10px]">
                    <div class="w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative">
                        <Icon icon="material-symbols:arrow-left-alt" width="24" height="24" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
    
                    <div class="w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative">
                        <Icon icon="material-symbols:arrow-right-alt" width="24" height="24" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
                </div>
            </header> -->

            <!-- <main class="flex gap-[26px] overflow-auto no-scrollbar">
                <div v-for="(restaurant, index) in restaurants" :key="index" class="border-[1px] border-grey-50 p-[12px] rounded-[8px] min-w-[278px]">
                    <div :style="getBackgroundStyle(restaurant.imageUrl)"></div>
                    <router-link to="/restaurant"><div class="font-semibold mt-[12px]">{{ restaurant.name }}</div></router-link>
                    <div class="my-[12px] text-caption text-grey-200"><span class="text-link text-medium">Location:</span> {{ restaurant.location }}</div> -->
                    <!-- <div class="flex items-center gap-[10px]">
                        <Icon icon="ant-design:star-filled" width="24" height="24" class="text-[#FADB14]" />
                        <div class="text-caption text-grey-200">{{ restaurant.reviews }} reviews</div>
                    </div> -->
                <!-- </div> -->
            <!-- </main> -->
        <!-- </section> -->

        <section class="lg:px-[60px] px-[10px]">
            <header class="my-[37px] items-center">
                <div class="lg:text-[28px] text-heading-4 font-semibold font-sourceSerifPro">All restaurants near you</div>
            </header>

            <main>
                <div v-if="loading" class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-[16px] overflow-auto no-scrollbar">
                    <div  v-for="index in 10" :key="index" class="border border-gray-200 rounded-lg p-4 animate-pulse">
                        <div class="bg-gray-100 h-40 rounded-lg"></div> <!-- Skeleton for image -->
                        <div class="mt-4">
                            <div class="bg-gray-100 h-6 rounded w-3/4"></div> <!-- Skeleton for title -->
                            <div class="bg-gray-100 h-4 rounded mt-2 w-5/6"></div> <!-- Skeleton for location -->
                        </div>
                    </div>
                </div>
                <div v-else class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-[16px] overflow-auto no-scrollbar">
                    <div v-for="(store, index) in stores" :key="index" class="border-[1px] border-grey-50 lg:p-[12px] p-[8px] rounded-[8px] ">
                        <router-link :to="`/restaurants/${store.restaurantId}/store/${store.id}`">
                            <div :style="getBackgroundStyle('https://png.pngtree.com/png-clipart/20220903/ourlarge/pngtree-chef-restaurant-logo-png-image_6136204.png')"></div>
                            <div class="font-semibold mt-[12px]">{{ store.name }}</div>
                            <div class="my-[12px] text-caption text-grey-200">
                                <span class="text-link text-medium">Location:</span> 
                                {{ `${store.address?.street} ${store.address?.lga} ${store.address?.state} ${store.address?.country}` }}
                            </div>
                            <!-- <div class="flex items-center gap-[10px]">
                                <Icon icon="ant-design:star-filled" width="24" height="24" class="text-[#FADB14]" />
                                <div class="text-caption text-grey-200">{{ restaurant.reviews }} reviews</div>
                            </div> -->
                        </router-link>
                    </div>
                </div>
            </main>
        </section>
    </main>

    <footer class="mt-[60px] border-t-[1px]">
        <div class="grid bg-[#fff] lg:grid-cols-2 lg:px-[60px] px-[20px] py-[60px]">
            <router-link to="/" class="flex items-center gap-[6px] bg-white w-fit py-[8px] rounded-[50px] h-fit">
                <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48"/>
                <div class="font-itim lg:text-heading-5">Foodcart</div>
            </router-link>

            <div class="lg:flex lg:gap-[120px] grid lg:grid-cols-2 gap-[40px] lg:ml-auto ml-0 lg:mt-0 mt-[50px]">
                <div class=""> 
                    <div class="text-heading-6 font-medium">COMPANY</div>
                    <div class="text-caption mt-[24px] grid gap-[14px]">
                        <router-link to="/" class="">Home</router-link>
                        <router-link to="about-us" class="">How it works</router-link>
                        <!-- <router-link to="" class="">Faq</router-link> -->
                        <router-link to="blog" class="">Blog</router-link>
                    </div>
                </div>

                <div class="">
                    <div class="text-heading-6 font-medium">GET HELP</div>
                    <div class="text-caption mt-[24px] grid gap-[14px]">
                        <div class="">Contact Us</div>
                        <div>
                            <a href="tel:+2349166190024" class="">+234 916 619 0024</a>
                        </div>
                        <div>
                            <a href="mailto:Sales@foodcart.ng" class="">Sales@foodcart.ng</a> 
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="text-heading-6 font-medium">LEGAL</div>
                    <div class="text-caption mt-[24px] grid gap-[14px]">
                        <router-link to="/privacy-policy" class="">Privacy Policy</router-link>
                        <router-link to="/terms-of-services" class=""><div class="">Terms of service</div></router-link>
                        <div class="">Cookie settings</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-[#fff] lg:px-[60px] py-[34px] lg:flex items-center lg:justify-between text-caption">
            <div class="lg:flex text-center gap-[24px]">
                <div class="">© 2024 FoodCart. All rights reserved.</div>
                <router-link to="/privacy-policy" class=""><div class="underline">Privacy Policy</div></router-link>
                <router-link to="/terms-of-services" class=""><div class="underline">Terms of Service</div></router-link>
                <div class="underline">Cookie settings</div>
            </div>

            <div class="text-white flex gap-[16px] lg:mt-0 mt-[20px] w-fit lg:ml-auto mx-auto">
                <a href="https://x.com/foodcartng" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:twitter-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.facebook.com/foodcart.tech" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:facebook-filled" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.youtube.com/channel/UCegkuRFbZMXD2rOJhtFg2Kg" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:youtube-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.instagram.com/foodcart.ng/" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:instagram-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue'
import { getRestaurants, items } from './restaurant.helper';

const { stores, loading } = getRestaurants();

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px',
    width: '100%',
    borderRadius: '8px'
});

const activeIndex = ref(0);

const setActive = (index: any) => {
    activeIndex.value = index;
};

const activeOption = ref('Delivery');

</script>

<style lang="scss" scoped>

</style>