<template>
    <div class="">
        <header class="border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]">
            <div class="cursor-pointer" @click="router.back">
                <div class="rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative">
                    <Icon icon="ant-design:arrow-left-outlined" width="18" height="18" class="text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </div>
            </div>
            <h3 class="font-medium text-sub-heading">Back to cart</h3>
        </header>

        <main class="grid lg:grid-cols-2">
            <div class="lg:h-[90vh] bg-grey-50 overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px] no-scrollbar">
                <div class="font-medium">Order Information & Review</div>
                <div class="font-medium text-caption text-grey-200">By placing your order, you agree to foodcart's <router-link to="/privacy-policy" class="text-primary-300 underline">Privacy</router-link> and <router-link to="/privacy-policy" class="text-primary-300 underline">Policy</router-link></div> 
                <div v-for="(menu, index) in cartMenus" :key="index" class="flex justify-between cursor-pointer border-b-[1px] border-solid border-grey-50 py-[24px]">
                    <div class="flex gap-[8px]">
                        <div :style="getCartBackgroundStyle(menu.imageUrl, 60)"></div>
                        <div class="">
                            <div class="font-semibold">{{ menu.name }}</div>
                            <div class="text-caption text-grey-200">{{ menu.description }}</div>
                        </div>
                    </div>

                    <div class="text-caption text-right grid gap-[8px]">
                        <div class="">{{ menu.price }}</div>
                        <div class="text-grey-200">Qty: {{ menu.quantity }}</div>
                    </div>
                </div>

                <!-- <div class="mt-[42px] mb-[20px] inline-block">Do you have an existing device?</div>
                
                <div class="flex gap-[16px] text-grey-200">
                    <RadioButton value="Pick-up" />
                    <RadioButton value="Delivery" />
                </div>

                <div class="text-caption text-grey-200 mt-[22px] border-b-[1px] border-solid border-grey-50  mb-[32px]">
                    Address:<br />
                    816 Mission St, San Francisco, CA.<br />
                    (0801 2345 6789)
                </div> -->

                <div class="py-[24px] grid gap-[16px]">
                    <div class="flex items-center justify-between">
                        <div class="text-grey-200 text-body-2">Sub Total</div>
                        <div class="">{{ orderData?.subTotalPrice }}</div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="text-grey-200 text-body-2">Service Fee</div>
                        <div class="">{{ orderData?.serviceFee || '2' }}</div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="">Total</div>
                        <div class="">{{ orderData?.totalPrice }}</div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="h-[90vh] overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px]">
                    <div class="font-medium">Payment Details</div>
                    <div class="font-medium text-caption text-grey-200">Complete your purchase by providing your information and  make payment</div> 

                    <div class="grid gap-[8px] my-[42px]">
                        <!-- <InputField
                            type="text"
                            label="Full Name"
                            name="name"
                            :value=""
                        /> -->
                        <div class="">
                            <div class="text-grey-200 text-body-2">Name</div>
                            <div class="text-body-2 font-medium">{{ user?.firstName + ' ' + user?.lastName }}</div>
                        </div>

                        <div class="">
                            <div class="text-grey-200 text-body-2">Email</div>
                            <div class="text-body-2 font-medium">{{ user?.email }}</div>
                        </div>

                        <div class="">
                            <div class="text-grey-200 text-body-2">Phone</div>
                            <div class="text-body-2 font-medium">{{ user?.phone }}</div>
                        </div>
                    </div>

                    <div class="mb-[42px]">
                        <div class="font-medium">Payment Details</div>
                        <div class="grid gap-[16px] text-grey-200 text-body-2 mt-[16px]">
                            <RadioButton 
                                value="Pay with Bank Transfer (Recommended)" 
                                :isChecked="isChecked"
                                @change="handleIsChecked"

                            />
                            <!-- <RadioButton value="Pay with USSD" />
                            <RadioButton value="Pay with Direct Bank Charge" /> -->
                        </div>
                    </div>

                    <ActionButton 
                        theme="primary" 
                        label="Make Payment" 
                        class="whitespace-nowrap" 
                        :handleClick="() => makePayment()" 
                        :disable="!isChecked" 
                    />
                </div>
            </div>
        </main>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import StoreService from '@/utility/services/microsite/store.service';
import { Icon } from '@iconify/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { formatPrice } from '@/utility/helper/utils'
import router from '@/router';

const user = ref<any>(JSON.parse(localStorage.getItem('user') || 'null')); 
const isChecked = ref<boolean>(false)

watch(user, (newValue) => {
    if (newValue) {
        localStorage.setItem('user', JSON.stringify(newValue)); 
    }
});

watch(
    () => localStorage.getItem('user'),
    (newValue) => {
        user.value = JSON.parse(newValue || 'null'); 
    }
);

const getCartBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const makePayment = async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params
    router.push({ 
        path: `/initiate/${orderId}`, 
        query: { storeId, restaurantId } 
    })
}

interface CartItem extends Record<string, string | number> {
    imageUrl: string;
    name: string;
    price: string;
    quantity: number;
    totalPrice: number;
}

const cartMenus = ref<CartItem[]>([]);
const orderData = ref()

const route = useRoute();

onMounted(async () => {
    const { storeId, restaurantId } = route?.query; 
    const { orderId } = route?.params; 
    try {
        const cartData = await StoreService.storeOrderByIdRequest(restaurantId, storeId, orderId);
        orderData.value = cartData
        cartMenus.value = cartData.orderDetails || [];
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
});

const totalPrice = computed(() => {
    return cartMenus.value.reduce((acc, item) => acc + item.totalPrice, 0);
});

const handleIsChecked = () => {
    isChecked.value = !isChecked.value
}

</script>

<style scoped>

</style>